import { ToastContainer, toast } from "react-toastify";
import { Global, css } from '@emotion/react';
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useRoutes } from "react-router-dom";
import "./App.css";
import Confetti from 'react-confetti'
import 'bootstrap/dist/css/bootstrap.min.css';
import GetStartedPage from "./Pages/onboarding/GetStarted";
import SignUp from "./Pages/onboarding/SignUp";
import EnterOtp from "./Pages/onboarding/enterOtp";
import OnboardingPage from "./Pages/onboarding/onboarding";
import AccountSetUp from "./Pages/onboarding/accountSettingUp";
import UserDashboard from "./Pages/Dashboard/userDashboard";
import SignIn from "./Pages/onboarding/SignIn";
import OrderHistoryDashboard from "./Pages/Dashboard/orderHistoryDashboard";
import OrderHistoryDetails from "./Pages/Dashboard/orderHistoryDetails";
import CategorySetup from "./Pages/admin_module/dashboard/categorySetup";
import ProductTypeSetup from "./Pages/admin_module/dashboard/productTypeSetup";
import ProductSetup from "./Pages/admin_module/dashboard/productSetup";
import Application from "./Pages/admin_module/dashboard/application";
import TransactionLog from "./Pages/admin_module/dashboard/transactionLog";
import AdminDashboard from "./Pages/Dashboard/adminDashboard";
import ForgotPassword from "./Pages/onboarding/forgotPassword";
import NewPassword from "./Pages/onboarding/changePassword";
import UserManagement from "./Pages/admin_module/dashboard/userManagement";
import PromoManagement from "./Pages/admin_module/dashboard/promoManagement";
import Queue from "./Pages/queue/queue";
import RetailerDashboard from "./Pages/Dashboard/retailerDashboard";
import ForgetPasswordEnterOtp from "./Pages/onboarding/forgotPasswordEnterOtp";
// import Notification from "./Components/Notification";
import { useEffect, useState } from "react";


// toast.configure();

const GlobalStyles = () => (
  <Global
    styles={css`
    /* Apply global styles */
    body {
      font-family: 'Noto Sans', sans-serif;
      color: #333;
    }
  `}
  />
);

function App() {
  <GlobalStyles />
  toast.configure({
    autoClose: 7000, // 5 seconds
    // Other global options...
  });
  const userDatafromlocalstorage = JSON.parse(localStorage.getItem('user'));
  // console.log(userDatafromlocalstorage, "userlocal")
  const [confettiActive, setConfettiActive] = useState(false);
  const [windowDimension, setDimension] = useState({ width: window.innerWidth, height: window.innerHeight });

  const detectSize = () => {
    setDimension({ width: window.innerWidth, height: window.innerHeight });
  }

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    }
  }, [windowDimension]);

  useEffect(() => {
    if (confettiActive === true) {
      // debugger
      setTimeout(() => {
        setConfettiActive(false);
      }, 15000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confettiActive]);

  const routing = useRoutes([
    { path: "/", element: <SignUp /> },
    { path: "/signup", element: <SignUp /> },
    { path: "/signin", element: <SignIn /> },
    { path: "/enterotp", element: <EnterOtp /> },
    { path: "/forgotPasswordEnterOtp", element: <ForgetPasswordEnterOtp /> },
    { path: "/forgotpassword", element: <ForgotPassword /> },
    { path: "/changepassword", element: <NewPassword /> },
    { path: "/onboarding", element: userDatafromlocalstorage !== null ? <OnboardingPage /> : <Navigate to="/signin" replace /> },
    { path: "/settingup", element: <AccountSetUp /> },
    { path: "/dashboard", element: userDatafromlocalstorage !== null ? <UserDashboard setConfettiActive={setConfettiActive} confettiActive={confettiActive} /> : <Navigate to="/signin" replace /> },
    { path: "/admindashboard", element: userDatafromlocalstorage?.user?.user_type_id === 1 || userDatafromlocalstorage?.user?.user_type_id === 2 ? <AdminDashboard setConfettiActive={setConfettiActive} confettiActive={confettiActive} /> : <Navigate to="/signin" replace /> },
    { path: "/order-history", element: <OrderHistoryDashboard /> },
    { path: "/order-history-details", element: <OrderHistoryDetails /> },
    { path: "/category-setup", element: <CategorySetup /> },
    { path: "/product-type-setup", element: <ProductTypeSetup /> },
    { path: "/product-setup", element: <ProductSetup /> },
    { path: "/admin-application", element: <Application /> },
    { path: "/admin-transaction-log", element: <TransactionLog /> },
    { path: "/admin-usermanagement", element: <UserManagement /> },
    { path: "/admin-promo-management", element: <PromoManagement /> },
    { path: "/queue", element: <Queue /> },
    // { path: "/fakeadmin", element: <AdminDashboard />},
    { path: "/retaildashboard", element: userDatafromlocalstorage?.user?.user_type_id === 4 ? <RetailerDashboard /> : <Navigate to="/signin" replace /> },
    { path: "*", element: <GetStartedPage /> },
  ]);

  return (
    <div>
      <ToastContainer position="top-center" />
      {/* <Notification /> */}
      {confettiActive &&
        <Confetti
          width={windowDimension.width}
          height={windowDimension.height}
          tweenDuration={1000}
        />
      }
      {routing}
    </div>
  );
}

export default App;
