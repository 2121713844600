import { CREATE_ORDER, CREATE_ORDER_FAILURE, CREATE_ORDER_SUCCESS, EXPORT_ORDERS, EXPORT_ORDERS_FAILURE, EXPORT_ORDERS_SUCCESS, EXPORT_TRANSACTION_LOGS, EXPORT_TRANSACTION_LOGS_FAILURE, EXPORT_TRANSACTION_LOGS_SUCCESS, GET_ADMIN_ARCHIVE_ORDERS, GET_ADMIN_ARCHIVE_ORDERS_FAILURE, GET_ADMIN_ARCHIVE_ORDERS_SUCCESS, GET_ADMIN_ORDER_BY_ID, GET_ADMIN_ORDER_BY_ID_FAILURE, GET_ADMIN_ORDER_BY_ID_SUCCESS, GET_ALL_ADMIN_ORDERS, GET_ALL_ADMIN_ORDERS_BY_STATUS, GET_ALL_ADMIN_ORDERS_BY_STATUS_FAILURE, GET_ALL_ADMIN_ORDERS_BY_STATUS_SUCCESS, GET_ALL_ADMIN_ORDERS_FAILURE, GET_ALL_ADMIN_ORDERS_SUCCESS, GET_ALL_QUEUE_ORDERS, GET_ALL_QUEUE_ORDERS_FAILURE, GET_ALL_QUEUE_ORDERS_SUCCESS, GET_ALL_TRANSACTION_LOG, GET_ALL_TRANSACTION_LOG_FAILURE, GET_ALL_TRANSACTION_LOG_SUCCESS, GET_ALL_USER_ORDERS, GET_ALL_USER_ORDERS_FAILURE, GET_ALL_USER_ORDERS_SUCCESS, RESET_EXPORT, RESET_ORDERS, SEARCH_ORDERS, SEARCH_ORDERS_FAILURE, SEARCH_ORDERS_SUCCESS, SEARCH_TRANSACTION_LOGS, SEARCH_TRANSACTION_LOGS_FAILURE, SEARCH_TRANSACTION_LOGS_SUCCESS, UPDATE_ORDER_STATUS, UPDATE_ORDER_STATUS_FAILURE, UPDATE_ORDER_STATUS_SUCCESS } from "../ActionTypes";
const INIT_STATE = {
    allAdminOrders: [],
    isloadingAllAdminOrders: false,
    allAdminOrdersDatamsg: "",
    allAdminOrdersByStatusId: [],
    isloadingAllAdminOrdersByStatusId: false,
    allAdminOrdersByStatusIdDatamsg: "",
    allUserOrders: [],
    isloadingAllUserOrders: false,
    allUserOrdersDatamsg: "",
    adminArchiveOrders: [],
    isloadingAdminArchiveOrders: false,
    adminArchiveOrdersDatamsg: "",
    adminOrderById: [],
    isloadingAdminOrderById: false,
    adminOrderByIdDatamsg: "",
    updateOrderStat: {},
    isloadingUpdateOrderStat: false,
    updateOrderStatDatamsg: "",
    createAnOrder: {},
    isloadingCreateAnOrder: false,
    createAnOrderDatamsg: "",
    allTransaction: [],
    isloadingAllTransaction: false,
    allTransactionDatamsg: "",
    allQueueOrders: [],
    isloadingAllQueueOrders: false,
    allQueueOrdersDatamsg: "",
    isSearchedOrders: false,
    logSheet:"",
    isExportingLog: false,
    exportedLogMsg: "",
    exportedLogSheet:false,
    orderLogSheet:"",
    isExportingOrderLog: false,
    exportedOrderLogMsg: "",
    exportedOrderLogSheet:false,
};
function OrderReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_ALL_ADMIN_ORDERS: {
            // debugger
            return {
                ...state,
                allAdminOrders: action.payload,
                isloadingAllAdminOrders: true,
                allAdminOrdersDatamsg: "",
            }
        }
        case GET_ALL_ADMIN_ORDERS_SUCCESS: {
            // debugger
            return {
                ...state,
                allAdminOrders: action.payload,
                isloadingAllAdminOrders: false,
                allAdminOrdersDatamsg: "",
            };
        }
        case GET_ALL_ADMIN_ORDERS_FAILURE: {
            return {
                ...state,
                allAdminOrders: action.payload,
                isloadingAllAdminOrders: false,
                allAdminOrdersDatamsg: "",
            };
        }
        case GET_ALL_ADMIN_ORDERS_BY_STATUS: {
            // debugger
            return {
                ...state,
                allAdminOrdersByStatusId: action.payload,
                isloadingAllAdminOrdersByStatusId: true,
                allAdminOrdersByStatusIdDatamsg: "",
                isSearchedOrders: false,
            }
        }
        case GET_ALL_ADMIN_ORDERS_BY_STATUS_SUCCESS: {
            // debugger
            return {
                ...state,
                allAdminOrdersByStatusId: action.payload,
                isloadingAllAdminOrdersByStatusId: false,
                allAdminOrdersByStatusIdDatamsg: "",
                isSearchedOrders: false,
            };
        }
        case GET_ALL_ADMIN_ORDERS_BY_STATUS_FAILURE: {
            return {
                ...state,
                allAdminOrdersByStatusId: action.payload,
                isloadingAllAdminOrdersByStatusId: false,
                allAdminOrdersByStatusIdDatamsg: "",
                isSearchedOrders: false,
            };
        }
        case GET_ALL_USER_ORDERS: {
            // debugger
            return {
                ...state,
                allUserOrders: action.payload,
                isloadingAllUserOrders: true,
                allUserOrdersDatamsg: "",
            }
        }
        case GET_ALL_USER_ORDERS_SUCCESS: {
            // debugger
            return {
                ...state,
                allUserOrders: action.payload,
                isloadingAllUserOrders: false,
                allUserOrdersDatamsg: "",
            };
        }
        case GET_ALL_USER_ORDERS_FAILURE: {
            return {
                ...state,
                allUserOrders: action.payload,
                isloadingAllUserOrders: false,
                allUserOrdersDatamsg: "",
            };
        }
        case GET_ADMIN_ARCHIVE_ORDERS: {
            // debugger
            return {
                ...state,
                adminArchiveOrders: action.payload,
                isloadingAdminArchiveOrders: true,
                adminArchiveOrdersDatamsg: "",
            }
        }
        case GET_ADMIN_ARCHIVE_ORDERS_SUCCESS: {
            // debugger
            return {
                ...state,
                adminArchiveOrders: action.payload,
                isloadingaAdminArchiveOrders: false,
                adminArchiveOrdersDatamsg: "",
            };
        }
        case GET_ADMIN_ARCHIVE_ORDERS_FAILURE: {
            return {
                ...state,
                adminArchiveOrders: action.payload,
                isloadingaAdminArchiveOrders: false,
                adminArchiveOrdersDatamsg: "",
            };
        }
        case GET_ADMIN_ORDER_BY_ID: {
            // debugger
            return {
                ...state,
                adminOrderById: action.payload,
                isloadingAdminOrderById: true,
                adminOrderByIdDatamsg: "",
            }
        }
        case GET_ADMIN_ORDER_BY_ID_SUCCESS: {
            // debugger
            return {
                ...state,
                adminOrderById: action.payload,
                isloadingAdminOrderById: false,
                adminOrderByIdDatamsg: "",
            };
        }
        case GET_ADMIN_ORDER_BY_ID_FAILURE: {
            return {
                ...state,
                adminOrderById: action.payload,
                isloadingAdminOrderById: false,
                adminOrderByIdDatamsg: "",
            };
        }
        case UPDATE_ORDER_STATUS: {
            // debugger
            return {
                ...state,
                updateOrderStat: action.payload,
                isloadingUpdateOrderStat: true,
                updateOrderStatDatamsg: "",
            }
        }
        case UPDATE_ORDER_STATUS_SUCCESS: {
            // debugger
            return {
                ...state,
                updateOrderStat: action.payload,
                isloadingUpdateOrderStat: false,
                updateOrderStatDatamsg: "",
            };
        }
        case UPDATE_ORDER_STATUS_FAILURE: {
            return {
                ...state,
                updateOrderStat: action.payload,
                isloadingUpdateOrderStat: false,
                updateOrderStatDatamsg: "",
            };
        }
        case CREATE_ORDER: {
            // debugger
            return {
                ...state,
                createAnOrder: action.payload,
                isloadingCreateAnOrder: true,
                createAnOrderDatamsg: "",
            }
        }
        case CREATE_ORDER_SUCCESS: {
            // debugger
            return {
                ...state,
                createAnOrder: action.payload,
                isloadingCreateAnOrder: false,
                createAnOrderDatamsg: "",
            };
        }
        case CREATE_ORDER_FAILURE: {
            return {
                ...state,
                createAnOrder: action.payload,
                isloadingCreateAnOrder: false,
                createAnOrderDatamsg: "",
            };
        }
        case GET_ALL_TRANSACTION_LOG: {
            // debugger
            return {
                ...state,
                allTransaction: action.payload,
                isloadingAllTransaction: true,
                allTransactionDatamsg: "",
            }
        }
        case GET_ALL_TRANSACTION_LOG_SUCCESS: {
            // debugger
            return {
                ...state,
                allTransaction: action.payload,
                isloadingAllTransaction: false,
                allTransactionDatamsg: "",
            };
        }
        case GET_ALL_TRANSACTION_LOG_FAILURE: {
            return {
                ...state,
                allTransaction: action.payload,
                isloadingAllTransaction: false,
                allTransactionDatamsg: "",
            };
        }
        case GET_ALL_QUEUE_ORDERS: {
            // debugger
            return {
                ...state,
                allQueueOrders: action.payload,
                isloadingAllQueueOrders: true,
                allQueueOrdersDatamsg: "",
            }
        }
        case GET_ALL_QUEUE_ORDERS_SUCCESS: {
            // debugger
            return {
                ...state,
                allQueueOrders: action.payload,
                isloadingAllQueueOrders: false,
                allQueueOrdersDatamsg: "",
            };
        }
        case GET_ALL_QUEUE_ORDERS_FAILURE: {
            return {
                ...state,
                allQueueOrders: action.payload,
                isloadingAllQueueOrders: false,
                allQueueOrdersDatamsg: "",
            };
        }
        case SEARCH_TRANSACTION_LOGS: {
            // debugger
            return {
                ...state,
                allTransaction: action.payload,
                isloadingAllTransaction: true,
                allTransactionDatamsg: "",
            }
        }
        case SEARCH_TRANSACTION_LOGS_SUCCESS: {
            // debugger
            return {
                ...state,
                allTransaction: action.payload,
                isloadingAllTransaction: false,
                allTransactionDatamsg: "",
            };
        }
        case SEARCH_TRANSACTION_LOGS_FAILURE: {
            return {
                ...state,
                allTransaction: action.payload,
                isloadingAllTransaction: false,
                allTransactionDatamsg: "",
            };
        }
        case SEARCH_ORDERS: {
            // debugger
            return {
                ...state,
                allAdminOrdersByStatusId: [],
                isloadingAllAdminOrdersByStatusId: true,
                allAdminOrdersByStatusIdDatamsg: "",
                isSearchedOrders: true,
            }
        }
        case SEARCH_ORDERS_SUCCESS: {
            return {
                ...state,
                allAdminOrdersByStatusId: action.payload,
                isloadingAllAdminOrdersByStatusId: false,
                allAdminOrdersByStatusIdDatamsg: "",
                isSearchedOrders: true,
            };
        }
        case SEARCH_ORDERS_FAILURE: {
            return {
                ...state,
                allAdminOrdersByStatusId: [],
                isloadingAllAdminOrdersByStatusId: false,
                allAdminOrdersByStatusIdDatamsg: "",
                isSearchedOrders: false,
            };
        }
        case RESET_ORDERS: {
            return {
                ...state,
                allAdminOrdersByStatusId: [],
                isloadingAllAdminOrdersByStatusId: false,
                allAdminOrdersByStatusIdDatamsg: "",
                isSearchedOrders: false,
            };
        }
        case EXPORT_TRANSACTION_LOGS: {
            return {
                ...state,
                logSheet:"",
                isExportingLog: true,
                exportedLogMsg: "",
                exportedLogSheet:false,
            };
        }
        case EXPORT_TRANSACTION_LOGS_SUCCESS: {
            return {
                ...state,
                logSheet:action.payload,
                isExportingLog: false,
                exportedLogMsg: "",
                exportedLogSheet:true,
            };
        }
        case EXPORT_TRANSACTION_LOGS_FAILURE: {
            return {
                ...state,
                logSheet:"",
                isExportingLog: false,
                exportedLogMsg: "",
                exportedLogSheet:false,
            };
        }
        case RESET_EXPORT: {
            return {
                ...state,
                logSheet:"",
                isExportingLog: false,
                exportedLogMsg: "",
                exportedLogSheet:false,
                orderLogSheet:"",
                isExportingOrderLog: false,
                exportedOrderLogMsg: "",
                exportedOrderLogSheet:false,
                
            };
        }
        case EXPORT_ORDERS: {
            return {
                ...state,
                orderLogSheet:"",
                isExportingOrderLog: true,
                exportedOrderLogMsg: "",
                exportedOrderLogSheet:false,
            };
        }
        case EXPORT_ORDERS_SUCCESS: {
            return {
                ...state,
                orderLogSheet:action.payload,
                isExportingOrderLog: false,
                exportedOrderLogMsg: "",
                exportedOrderLogSheet:true,
            };
        }
        case EXPORT_ORDERS_FAILURE: {
            return {
                ...state,
                orderLogSheet:"",
                isExportingOrderLog: false,
                exportedOrderLogMsg: "",
                exportedOrderLogSheet:false,
            };
        }
        default:
            return state;
    }
}
export default OrderReducer;

export const getOrderReducer = state => state.orderReducer