import React, { useCallback, useEffect, useState } from 'react';
import { Table, Modal, Button, Spin, Input } from "antd";
import ViewOrderDetailsModal from '../../../Components/OrdersAdmin/ViewOrderDetails';
import { getOrderReducer } from '../../../redux/Reducer/orderReducer';
import { useDispatch, useSelector } from 'react-redux';
import { exportOrders, getAllAdminOrdersByStatusId, resetExport, resetOrders, searchOrders } from '../../../redux/Actions';
import moment from 'moment';
import { FormatNumber } from '../../../Utils/cartmgt';
import { ExportIcon, SearchIcon } from '../../../asset/icons/icons';
import { debounce } from 'lodash';
import ExportPopconfirm from '../../../Components/Export/ExportPopconfirm';



const RejectedOrders = () => {
  // eslint-disable-next-line no-unused-vars
  const [tabKey, setTabKey] = useState(0);
  const { allAdminOrdersByStatusId, isloadingAllAdminOrdersByStatusId, isSearchedOrders, isExportingOrderLog, exportedOrderLogSheet } = useSelector(getOrderReducer);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [openExport, setOpenExport] = useState(false);
  const [openExportWhenClicked, setOpenExportWhenClicked] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null);


  const [rejectedOrderId, setRejectedOrderId] = useState();


  const handleOrderAction = (value) => {
    // debugger
    setOpen(true)
    setRejectedOrderId(value)
    // console.log(value)
  };
  const handleExport = () => {
    debugger
    if (Array.isArray(selectedDates) && selectedDates.length !== 0) {
      setOpenExportWhenClicked(true)
      let dates = {
        start_date: selectedDates[0]?.format('YYYY-MM-DD'),
        end_date: selectedDates[1]?.format('YYYY-MM-DD'),
        order_status_id: tabKey,
        is_archived: 1
      };
      dispatch(exportOrders(dates))

    }
  };
  useEffect(() => {
    debugger
    if (exportedOrderLogSheet) {
      setOpenExportWhenClicked(false)
    }
    return () => {
      resetExport()
    }
  }, [exportedOrderLogSheet])



  const onChange = (pagination, filters, sorter, extra) => {
    // console.log("params", pagination, filters, sorter, extra);
    // console.log("params", pagination, filters, sorter, extra);
    // dispatch(getAllSystemAdmin(pagination.current));
    dispatch(getAllAdminOrdersByStatusId({ "order_status_id": tabKey, page: pagination.current }))
  };
  const onShowSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };
  useEffect(() => {
    if (tabKey !== undefined) {
      dispatch(getAllAdminOrdersByStatusId({ "order_status_id": tabKey }))
    } else {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  // console.log(allAdminOrdersByStatusId,"allAdminOrdersByStatusId")
  useEffect(() => {
    debugger;
    if (allAdminOrdersByStatusId?.data?.length > 0) {
      console.log(allAdminOrdersByStatusId?.data, "data comes to component")
      setFilteredData(allAdminOrdersByStatusId?.data);
      setCurrentPage(1);
      setPageSize(10);
    } else {
      setFilteredData([]);
      setCurrentPage(1);
      setPageSize(10);
    }
  }, [allAdminOrdersByStatusId?.data, isSearchedOrders]);


  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      render: (text) => <span className="orderHistory-text">{text ?? ''}</span>,
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: "Transanction Reference",
      dataIndex: "reference",
      render: (text) => <span className="orderHistory-text">{text ?? ''}</span>,
      sorter: (a, b) => a?.reference?.localeCompare(b?.reference),
    },
    {
      title: "Customer",
      dataIndex: "user",
      render: (text, record) => (
        <span className="orderHistory-text">
          {text?.user_type?.id === 5 ?
            <span className="px-2 order-customer-type-legend-text direct-customer-text"></span>
            :
            record?.is_internal_order === 1 ?
              <span className="px-2 order-customer-type-legend-text internal-order-text"></span>
              :
              <span className="px-2 order-customer-type-text independent-customer-text">
              </span>
          }
          {text?.name ?? ''} <br />
        </span>
      ),
      sorter: (a, b) => a.user?.name?.localeCompare(b?.user?.name),
    },
    {
      title: "Date of Order",
      dataIndex: "created_at",
      render: (text) => <span className="orderHistory-text">{moment(text)?.format('MMM Do YYYY')}</span>,
      sorter: (a, b) => a?.created_at?.localeCompare(b?.created_at),
    },
    {
      title: "Time of Order",
      dataIndex: "created_at",
      render: (text) => <span className="orderHistory-text">{moment(text)?.format('h:mm A')}</span>,
      sorter: (a, b) => a?.created_at?.localeCompare(b?.created_at),
    },
    {
      title: "Branch",
      dataIndex: "location",
      render: (text) => <span className="orderHistory-text">{text?.name}</span>,
      sorter: (a, b) => a?.location?.name?.localeCompare(b?.location?.name),
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      render: (text, record) => {
        let statusClass = "";
        switch (text?.id) {
          case 0:
            statusClass = "orderHistory rejected";
            break;
          default:
            statusClass = "orderHistory rejected";
        }
        return (
          <span key={text?.id} className={statusClass}>
            {text?.name ?? ''}
          </span>
        );
      },
      sorter: (a, b) => a?.order_status?.name?.localeCompare(b?.order_status?.name),
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      render: (text) => <span className="orderHistory-text">{text === undefined || null ? "N/A" : FormatNumber(text)}</span>,
      sorter: (a, b) => a?.amount - b?.amount,
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (text, record) => (
        <Button type='text' onClick={() => handleOrderAction(text)} className="orderHistory-view-details">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
          >
            <path
              d="M8.3198 0.504883C10.0075 0.504883 11.6075 1.0904 13.0007 2.1744C14.3938 3.25048 15.5799 4.82504 16.4317 6.79523C16.4954 6.94556 16.4954 7.11964 16.4317 7.26206C14.7281 11.2024 11.6951 13.5603 8.3198 13.5603H8.31184C4.9445 13.5603 1.91149 11.2024 0.20792 7.26206C0.144235 7.11964 0.144235 6.94556 0.20792 6.79523C1.91149 2.85486 4.9445 0.504883 8.31184 0.504883H8.3198ZM8.3198 3.86765C6.5605 3.86765 5.13555 5.28396 5.13555 7.0326C5.13555 8.77332 6.5605 10.1896 8.3198 10.1896C10.0711 10.1896 11.4961 8.77332 11.4961 7.0326C11.4961 5.28396 10.0711 3.86765 8.3198 3.86765ZM8.32076 5.05276C9.41136 5.05276 10.303 5.93895 10.303 7.03086C10.303 8.11486 9.41136 9.00104 8.32076 9.00104C7.22219 9.00104 6.3306 8.11486 6.3306 7.03086C6.3306 6.89635 6.34652 6.76975 6.3704 6.64315H6.41021C7.29384 6.64315 8.01029 5.94686 8.04214 5.0765C8.1297 5.06068 8.22523 5.05276 8.32076 5.05276Z"
              fill="#45464E"
            />
          </svg>

        </Button>
      ),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };



  const usermanText = {
    width: '189px',
    flexShrink: 0,
    color: '#45464E',
    fontFamily: '"MADE TOMMY"',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px'
  };

  const exportButton = {
    display: "flex",
    height: "44px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid var(--Gray-100, #E6E7EC)",
    background: "var(--Backgrounds-Background-White, #FFF)",
    // color: "#FFF",
    fontFamily: '"MADE TOMMY"',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    color: "var(--Payaza-Colors-Payaza-Blue-800, #0E2354)",
    marginRight: "5px",
  };

  //   const filter = {
  //     color: "var(--Black-80, #52575C)",
  //     fontFamily: "Inter",
  //     fontSize: "13.138px",
  //     fontStyle: "normal",
  //     fontWeight: 500,
  //     lineHeight: "22.992px", // 175%
  //     letterSpacing: "-0.145px",
  // };

  // const filterDiv = {
  //     display: "flex",
  //     width: "588.756px",
  //     justifyContent: "flex-end",
  //     alignItems: "flex-start",
  //     gap: "16.423px",
  //     flexShrink: 0
  // };
  // const filterCount = {
  //     borderRadius: "410.569px",
  //     background: "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))",
  //     display: "flex",
  //     padding: "3.285px 6.138px 4.423px 6.57px",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     color: "var(--Black-80, #52575C)",
  //     textAlign: "center",
  //     fontFamily: "Inter",
  //     fontSize: "9.854px",
  //     fontStyle: "normal",
  //     fontWeight: 400,
  //     lineHeight: "normal"
  // };


  const orderRejectedError = 'error';
  const orderStatus = 0;
  const initialStage = false;
  const processingStage = false;
  const loadingStage = false;
  const pickUpStage = false;
  const orderRejected = true;

  const CollectionCreateFormModal = ({ open, onCreate, onCancel, initialValues }) => {
    // eslint-disable-next-line no-unused-vars
    const [formInstance, setFormInstance] = useState();
    return (
      <Modal
        open={open}
        title="Order Details"
        okText="Ok"
        cancelText="Cancel"
        width={800}
        okButtonProps={{
          autoFocus: true,
        }}
        onCancel={onCancel}
        destroyOnClose
        onOk={async () => {
          try {
            const values = await formInstance?.validateFields();
            formInstance?.resetFields();
            onCreate(values);
          } catch (error) {
            // console.log('Failed:', error);
          }
        }}
      >
        <ViewOrderDetailsModal
          orderRejectedError={orderRejectedError}
          orderStatus={orderStatus}
          orderRejected={orderRejected}
          initialStage={initialStage}
          processingStage={processingStage}
          loadingStage={loadingStage}
          pickUpStage={pickUpStage}
          id={rejectedOrderId}
          open={open}
          onCreate={onCreate}
          onCancel={() => setOpen(false)}
          initialValues={{
            modifier: "public",
          }}
        />
      </Modal>
    );
  };

  const [formValues, setFormValues] = useState();
  const [open, setOpen] = useState(false);
  const onCreate = (values) => {
    // console.log('Received values of form: ', values);
    setFormValues(values);
    setOpen(false);
  };

  const [searchQuery, setSearchQuery] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value.length > 0) {
        let payload = {
          searchQuery: value,
          order_status_id: tabKey
        }
        dispatch(searchOrders(payload));
      } else {
        dispatch(resetOrders());
        dispatch(getAllAdminOrdersByStatusId({ "order_status_id": tabKey, "is_archived": 1 }))
      }
    }, 300),
    [dispatch, tabKey]
  );
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    debouncedSearch(value);
  };


  const handleOpenExport = (newOpen) => {
    setOpenExport(newOpen);
  }
  const handleCloseExport = () => {
    setOpenExport(false);
    setSelectedDates(null);
  };
  const handleDateChange = (dates, dateStrings) => {
    setSelectedDates(dates);
  };

  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px", margin: "0px" }}>
        <div className="row">
          <div className="d-flex justify-content-start">
            <div className="col">
              <span style={usermanText}>Orders &gt; Rejected Orders</span> <span style={{ color: "#252A4D" }} className="mx-2 tag fw-bold">
                {/* {allAdminOrdersByStatusId?.meta?.total} */}
                {isSearchedOrders ? filteredData?.length : filteredData?.length === 0 ? 0 : allAdminOrdersByStatusId?.meta?.total}
              </span>
            </div>
            <div className="col d-flex justify-content-end">
              {/* <button onClick={1} style={exportButton}>
                Export
              </button> */}
              {/* <button style={newEmpButton}>
                Create Order
              </button> */}
              <ExportPopconfirm placement="bottomLeft" cancel={handleCloseExport} open={openExport} handleOpenChange={handleOpenExport} onChange={handleDateChange} confirm={handleExport} isLoading={isExportingOrderLog} selectedDates={selectedDates} openWhenClicked={openExportWhenClicked}>
                <Button
                  icon={<ExportIcon />}
                  style={exportButton}
                >
                  Export
                </Button>
              </ExportPopconfirm>


              <pre>{JSON.stringify(formValues, null, 2)}</pre>

              <CollectionCreateFormModal
                open={open}
                onCreate={onCreate}
                onCancel={() => setOpen(false)}
                initialValues={{
                  modifier: "public",
                }}
              />
              {/* <Button type="primary" onClick={showModal}>
              Open Modal with async logic
            </Button> */}
            </div>
            {/* <Modal
              title="Create New Employee"
              open={open}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <p>Employee</p>
            </Modal> */}
          </div>
        </div>

        {/* <div className="row mt-5 mb-3"> */}
        {/* <div className="d-flex justify-content-start">
            <div className="col-3">
              <Input placeholder="Basic usage" />
            </div>
            <div className="col d-flex justify-content-end" style={filterDiv}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M11.9507 10.0641V16.5346C11.9836 16.781 11.9015 17.0437 11.7126 17.2162C11.3924 17.5364 10.8751 17.5364 10.5548 17.2162L8.90433 15.5657C8.71546 15.3768 8.63335 15.1223 8.6662 14.8842V10.0641H8.64156L3.91181 4.00407C3.63262 3.65098 3.69831 3.13367 4.0514 2.85448C4.20742 2.73952 4.37986 2.67383 4.56051 2.67383H16.0564C16.2371 2.67383 16.4095 2.73952 16.5655 2.85448C16.9186 3.13367 16.9843 3.65098 16.7051 4.00407L11.9754 10.0641H11.9507Z"
                    fill="#52575C"
                  />
                </svg>
              </span>{" "}
              <span style={filter} className="">
                Filter
              </span>
              <span style={filterCount}>0</span>
            </div>
          </div> */}
        {/* </div> */}
        <div className="d-flex justify-content-between mb-3">
          <div className="col-12 col-md-5 col-sm-5 mt-3">
            <Input
              placeholder={"Search by order id, customer name or location"}
              style={{ width: '100%', border: 'none', color: '#BECOCA', backgroundColor: '#F7F7F7', cursor: "text" }}
              value={searchQuery}
              onChange={handleSearch}
              prefix={<SearchIcon />}
            />
          </div>

          <div className="col-12 mt-3 col-md-5 col-sm-5">
            <span>
              <span className="px-2 order-customer-type-legend-text internal-order-text orderHistory-text"></span><span className='fw-bold' style={{ color: '#328835' }}>Internal Order </span>
              <span className="px-2 order-customer-type-legend-text direct-customer-text orderHistory-text"></span> <span className='fw-bold' style={{ color: '#7f2da5' }}>Direct Customer</span>
              <span className="px-2 order-customer-type-legend-text independent-customer-text orderHistory-text"></span> <span className='fw-bold' style={{ color: '#b9892e' }}>Independent Customer</span>
            </span>
          </div>
        </div>
        <div className='border p-3 rounded'>

          <Spin spinning={isloadingAllAdminOrdersByStatusId} tip="Loading...">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              scroll={{ x: 1000 }}
              // dataSource={allAdminOrdersByStatusId?.data}
              dataSource={filteredData}
              onChange={onChange}
              pagination={
                isSearchedOrders
                  ? {
                    current: currentPage,
                    pageSize: pageSize,
                    total: filteredData?.length,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    onShowSizeChange: onShowSizeChange,
                    showTotal: (total) => `Total ${total} items`,
                  }
                  : {
                    current: allAdminOrdersByStatusId?.meta?.current_page,
                    pageSize: allAdminOrdersByStatusId?.meta?.per_page,
                    showSizeChanger: false,
                    pageSizeOptions: ["10", "20", "30", "40"],
                    showQuickJumper: true,
                    total: allAdminOrdersByStatusId?.meta?.total,
                    style: {
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    },
                  }
              }
            />
          </Spin>
        </div>
      </div>
    </>
  );
};
export default RejectedOrders;
