import React from 'react';
import { Divider, Popconfirm, DatePicker } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './export.css';

const { RangePicker } = DatePicker;

const SelectDateField = ({ onChange }) => {
    const handleDateChange = (dates, dateStrings) => {
        onChange(dates, dateStrings);
    };

    return (
        <div style={{ width: '100%' }}>
            <Divider />
            <div style={{ marginBottom: '20px' }}>
                <span>Pick a start and end date to generate report</span>
            </div>
            <RangePicker style={{ width: '100%' }} onChange={handleDateChange} format="YYYY-MM-DD" />
        </div>
    );
};

const HeaderField = ({ onCancel }) => {
    return (
        <div className='d-flex justify-content-between align-items-center'>
            <span className='fw-bold'>Export settings</span>
            <CloseOutlined style={{ cursor: "pointer" }} onClick={onCancel} />
        </div>
    );
};

const ExportPopconfirm = ({ children, placement, open, cancel, handleOpenChange, onChange, confirm, isLoading, selectedDates, openWhenClicked }) => {


    return (
        <Popconfirm
            open={openWhenClicked?openWhenClicked:open}
            title={<HeaderField onCancel={cancel} />}
            description={<SelectDateField onChange={onChange} />}
            onConfirm={confirm}
            onOpenChange={handleOpenChange}
            onCancel={cancel}
            okText={"Export file"}
            okButtonProps={{
                autoFocus: true,
                block: true,
                loading:isLoading,
                size: 'large',
                disabled: !selectedDates || isLoading,
                className: !selectedDates ? 'custom-disabled-button' : '',
                style: {
                    backgroundColor: '#2F355B',
                    color: '#fff',
                    margin: '20px 0',
                    display: 'block',
                    width: '100%',
                },
            }}
            placement={placement}
            arrow={false}
            icon={false}
            cancelButtonProps={{ hidden: true }}
        >
            {children}
        </Popconfirm>
    );
};

export default ExportPopconfirm;
