// import { toastr } from "react-redux-toastr";
import api from "../../Service/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const GetAllAdminOrdersApi = async (payload) => {
    // debugger
    console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/orders`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const GetAllAdminOrdersByStatusIdApi = async (payload) => {
    // debugger
    console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/orders?order_status_id=${payload.order_status_id}&is_archived=${payload.is_archived === undefined ? 0 : payload.is_archived}&page=${payload.page === undefined ? "" : payload.page}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data;
            } else {
                // toast.error(response.error);
                console.log(response)
                return response.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex)
            throw ex;
        }
    }
};

export const GetAllUserOrdersApi = async (body, payload) => {
    // debugger
    // console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/orders?user_id=${body}&page=${payload === undefined ? 1 : payload}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const GetAdminArchiveOrdersApi = async (payload) => {
    // debugger
    // console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/orders/${payload === undefined ? 1 : payload}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const GetAdminOrderByIdApi = async (payload) => {
    // debugger
    // console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/orders/${payload === undefined ? 103 : payload}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const UpdateOrderStatusApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/orders/status`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                //   toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else {
                //   toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            //   toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const CreateOrderApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.post(`/orders`, payload);
            if (response.status === 200) {
                // debugger
                console.log(response)
                //   toast.success(response.data.message);
                // localStorage.setItem("usersignupemail", JSON.stringify(payload.email));
                return response.data;
            } else {
                //   toast.error(response.data.message);
                console.log(response)
            }
        } catch (error) {
            //   toast.error(error.response.data.message);
            console.log(error.response.data.message)
            throw error;
        }
    }
};

export const GetAllTransactionLogApi = async (payload) => {
    // debugger
    // console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/transactions?page=${payload === undefined ? 1 : payload}`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const GetAllQueueOrdersApi = async (payload) => {
    // debugger
    console.log(payload)
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/queue/orders`);
            if (response.status === 200) {
                // debugger
                // toast.success(response.data.message);
                console.log(response.data)
                return response.data;
            } else {
                // toast.error(response.error);
                console.log(response.data)
                return response.data;
            }
        } catch (ex) {
            // toast.error(ex.message);
            console.log(ex.data)
            throw ex;
        }
    }
};

export const SearchOrdersApi = async (payload) => {
    debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            debugger
            const response = await api.get(`/orders/q/search?keyword=${payload?.searchQuery}`);
            if (response.status === 200) {
                const filteredData = response?.data?.data?.filter(user => user?.order_status_id === Number(payload?.order_status_id));
                return { ...response?.data, data: filteredData };
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const SearchTransactionLogsApi = async (payload) => {
    // debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection", "Try again");
    } else {
        try {
            const response = await api.get(`/transactions/q/search?keyword=${payload}`);
            if (response.status === 200) {
                console.log(response)
                return response.data;
            } else {
                toast.error(response.error);
            }
        } catch (ex) {
            toast.error(ex);
            throw ex;
        }
    }
};

export const ExportTransactionLogsApi = async (payload) => {
    if (navigator.onLine === false) {
        toast.error("No Internet Connection. Try again");
        return;
    }
    try {
        const response = await api.get(`/transactions/y/export?start_date=${payload?.start_date}&end_date=${payload?.end_date}`, { responseType: "blob" });
        if (response.status === 200) {
            if (response.data instanceof Blob) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Nibbles Bakery Transaction Logs from ${payload?.start_date} to ${payload?.end_date}.xlsx`); // Specify the file name
                document.body.appendChild(link);
                link.click();
                toast.success("Transaction Logs Sheet Downloaded Successfully")
                link.remove();
                window.URL.revokeObjectURL(url); // Free up memory
            } else {
                console.error('Response data is not a Blob:', response.data);
                toast.error('Failed to download: Response is not a valid file.');
            }
        } else {
            toast.error(response.error || 'Failed to export transaction logs.');
        }
    } catch (error) {
        console.error("An error occurred:", error);
        toast.error('An error occurred while exporting logs.');
    }
};

export const ExportOrdersApi = async (payload) => {
    debugger
    if (navigator.onLine === false) {
        toast.error("No Internet Connection. Try again");
        return;
    }
    try {
        const response = await api.get(`/orders/y/export?start_date=${payload?.start_date}&end_date=${payload?.end_date}&order_status_id=${payload?.order_status_id}&is_archived=${payload.is_archived === undefined ? 0 : payload.is_archived}`, { responseType: "blob" });
        if (response.status === 200) {
            if (response.data instanceof Blob) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Nibbles Bakery Orders Log from ${payload?.start_date} to ${payload?.end_date}.xlsx`); // Specify the file name
                document.body.appendChild(link);
                link.click();
                toast.success("Orders Log Sheet Downloaded Successfully")
                link.remove();
                window.URL.revokeObjectURL(url); // Free up memory
            } else {
                console.error('Response data is not a Blob:', response.data);
                toast.error('Failed to download: Response is not a valid file.');
            }
        } else {
            toast.error(response.error || 'Failed to export transaction logs.');
        }
    } catch (error) {
        console.error("An error occurred:", error);
        toast.error('An error occurred while exporting logs.');
    }
};