import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, message } from "antd";
import nibbleslogo from "../../asset/nibbleslogo.jpg";
import "./onboarding.css";
import BestDescribeOnb from "../../Components/bestDescribe";
import AboutBusinessOnb from "../../Components/aboutBusiness";
import BusinessLocationOnb from "../../Components/businesslocationOnb";
import CuriousJourney from "../../Components/curiousJourney";
import WhoElseOnTeam from "../../Components/whoElseOnTeam";
import MoreDetailsAboutYou from "../../Components/oneLastThing";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import {
  getUserProfileDetails,
  postBusinessContact,
  postBusinessDetails,
  postBusinessHistory,
  postBusinessLocation,
  postUserDetails,
  postUserTypeData,
} from "../../redux/Actions";
// import { Logout } from '../../Utils/logout';

const OnboardingPage = () => {
  // const { token } = theme.useToken();
  const {
    usertypePostData,
    usertypePostingData,
    businessDetails,
    postingbusinessDetails,
    businessLocation,
    postingbusinessLocation,
    businessHistory,
    postingbusinessHistory,
    businessContact,
    userOnboardingDetails,
    postinguserOnboardingDetails,
    userProfileDetails,
    authUser,
    // postinguserOnboardingDetails
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [user_type_id, setSelectedOption] = useState("");
  const [businessDetailsForm, setBusinessDetailsForm] = useState({
    name: "",
    description: "The best bakery in-town",
    years_of_experience: 0,
    cac_reg_number: "",
    office_phone: "",
    nature_of_business: "",
  });
  const [businessLocationForm, setBusinessLocationForm] = useState({
    branch: "",
    country: "Nigeria",
    state: "",
    lga: "",
    address: "",
  });
  const [businessHistoryForm, setBusinessHistoryForm] = useState({
    strategy_brief: "",
    is_new_partner: null,
    annual_turnover: null,
    min_turnover_per_month: null,
    has_delivery_van: null,
    distribute_loaf: null,
    nature_of_business: "Bakery",
  });
  const [businessContactForm, setBusinessContactForm] = useState({
    contact_name: "",
    contact_email: "",
    contact_phone: "",
  });
  const [userData, setUserData] = useState({
    date_of_birth: "",
    gender: "",
    country: "Nigeria",
    state: "",
    lga: "",
    address: "",
    phone: "",
    whatsapp_phone: "",
    location_id: null,
    next_of_kin: [
      {
        relationship_type_id: null,
        name: "",
        phone: "",
        address: "",
      },
    ],
    socials: [
      {
        icon: "",
        link: "",
      },
    ],
  });
  const navigate = useNavigate();

  const next = () => {
    if (current === 0) {
      // debugger
      dispatch(postUserTypeData({ user_type_id }));
      localStorage.setItem("user_type_id", JSON.stringify(user_type_id));
    } else if (current === 1) {
      const cacRegNumber = businessDetailsForm.cac_reg_number;
      const updatedCacRegNumber =
        cacRegNumber === "" ||
        cacRegNumber === undefined ||
        cacRegNumber === null
          ? "xxxxx"
          : cacRegNumber;
      dispatch(
        postBusinessDetails({
          ...businessDetailsForm,
          cac_reg_number: updatedCacRegNumber,
        })
      );
      // setCurrent(current + 1);
    } else if (current === 2) {
      dispatch(postBusinessLocation(businessLocationForm));
      //   setCurrent(current + 1);
    } else if (current === 3) {
      dispatch(postBusinessHistory(businessHistoryForm));
    //   setCurrent(current + 1);
    } else if (current === 4) {
      dispatch(postUserDetails(userData));
    //   setCurrent(current + 1);
    }
    return null;
  };

  const skip = () => {
    // setCurrent(current + 1);
      message.success("Processing complete!");
      navigate("/settingup");
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const handleOnbcompleted = () => {
    // debugger
    // navigate("/settingup")
    if (usertypeid === 4) {
      dispatch(postUserDetails(userData));
      // Logout()
      // navigate("/signin")
    } else {
      dispatch(postBusinessContact(businessContactForm));
    }
  };

  const usertypeid = JSON.parse(localStorage.getItem("user_type_id"));
  console.log(usertypeid);
  useEffect(() => {
    // dispatch(getCountry())
    if (businessContact === true) {
      message.success("Processing complete!");
      navigate("/settingup");
    }
    if (usertypeid === 4) {
      if (userOnboardingDetails.status === true) {
        message.success("Processing complete!");
        navigate("/settingup");
      } else {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessContact, userOnboardingDetails, usertypeid]);
  // console.log(businessContact)

  useEffect(() => {
    dispatch(getUserProfileDetails(authUser?.user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current === 0) {
      if (usertypePostData.status === true) {
        setCurrent(current + 1);
        dispatch(getUserProfileDetails(authUser?.user.id));
      }
    }
    if (current === 1) {
      if (businessDetails === true) {
        setCurrent(current + 1);
        dispatch(getUserProfileDetails(authUser?.user.id));
      }
    }
    if (current === 2) {
      if (businessLocation === true) {
        setCurrent(current + 1);
        dispatch(getUserProfileDetails(authUser?.user.id));
      }
    }
    if (current === 3) {
        if (businessHistory === true) {
          setCurrent(current + 1);
          dispatch(getUserProfileDetails(authUser?.user.id));
        }
    }
    if (current === 4) {
        if (userOnboardingDetails.status === true) {
          setCurrent(current + 1);
          dispatch(getUserProfileDetails(authUser?.user.id));
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usertypePostData, businessDetails, businessLocation, businessHistory, userOnboardingDetails]);

  useEffect(() => {
    if (userProfileDetails.status === true) {
      setSelectedOption(userProfileDetails?.data?.user_type_id);
      setBusinessDetailsForm({
        ...businessDetailsForm,
        name: userProfileDetails?.data?.business?.name,
        years_of_experience:
          userProfileDetails?.data?.business?.years_of_experience,
        cac_reg_number: userProfileDetails?.data?.business?.cac_reg_number,
        office_phone: userProfileDetails?.data?.business?.office_phone,
        nature_of_business:
          userProfileDetails?.data?.business?.nature_of_business,
      });
      setBusinessLocationForm({
        ...businessLocationForm,
        branch: userProfileDetails?.data?.business?.branch,
        country: "Nigeria",
        state: userProfileDetails?.data?.business?.state,
        lga: userProfileDetails?.data?.business?.lga,
        address: userProfileDetails?.data?.business?.address,
      });
      setBusinessHistoryForm({
        ...businessHistoryForm,
            strategy_brief: userProfileDetails?.data?.business?.strategy_brief,
            is_new_partner: userProfileDetails?.data?.business?.is_new_partner === 1 ? true : userProfileDetails?.data?.business?.is_new_partner === 0 ? false : null,
            annual_turnover: userProfileDetails?.data?.business?.annual_turnover,
            min_turnover_per_month: userProfileDetails?.data?.business?.min_turnover_per_month,
            has_delivery_van: userProfileDetails?.data?.business?.has_delivery_van === 1 ? true : userProfileDetails?.data?.business?.has_delivery_van === 0 ? false : null,
            distribute_loaf: userProfileDetails?.data?.business?.distribute_loaf === 1 ? true : userProfileDetails?.data?.business?.distribute_loaf === 0 ? false : null,
            // nature_of_business: userProfileDetails?.data?.business?.branch,
      });
      setUserData({
        ...userData,
            date_of_birth: userProfileDetails?.data?.date_of_birth,
            gender: userProfileDetails?.data?.gender,
            name: userProfileDetails?.data?.name,
            country: userProfileDetails?.data?.country === null ? "Nigeria" : userProfileDetails?.data?.country,
            state: userProfileDetails?.data?.state === null ? userProfileDetails?.data?.business?.state : userProfileDetails?.data?.state,
            lga: userProfileDetails?.data?.lga === null ? userProfileDetails?.data?.business?.lga : userProfileDetails?.data?.lga,
            location_id: userProfileDetails?.data?.location?.id === null ? userProfileDetails?.data?.location_id : userProfileDetails?.data?.location?.id,
            address: userProfileDetails?.data?.address === null ? userProfileDetails?.data?.business?.address : userProfileDetails?.data?.address,
            phone: userProfileDetails?.data?.phone,
            whatsapp_phone: userProfileDetails?.data?.business?.office_phone,
            next_of_kin: [
            {
                address: userProfileDetails?.data?.address === null ? userProfileDetails?.data?.business?.address : userProfileDetails?.data?.address,
            },
            ],
            // socials: [
            // {
            //     icon: userProfileDetails?.data?.business?.branch,
            //     link: userProfileDetails?.data?.business?.branch,
            // },
            // ],
            // nature_of_business: userProfileDetails?.data?.business?.branch,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileDetails]);
  // console.log(userProfileDetails, "userProfileDetails");
  // console.log(businessDetails, "businessDetails");

  const steps = [
    {
      title: "First",
      content: (
        <BestDescribeOnb
          setSelectedOption={setSelectedOption}
          selectedOption={user_type_id}
          usertypePostingData={usertypePostingData}
        />
      ),
    },
    {
      title: "Second",
      content: (
        <AboutBusinessOnb
          setBusinessDetailsForm={setBusinessDetailsForm}
          usertypeid={usertypeid}
          businessDetailsForm={businessDetailsForm}
          postingbusinessDetails={postingbusinessDetails}
        />
      ),
    },
    {
      title: "Third",
      content: (
        <BusinessLocationOnb
          setBusinessLocationForm={setBusinessLocationForm}
          businessLocationForm={businessLocationForm}
          usertypeid={usertypeid}
          postingbusinessLocation={postingbusinessLocation}
        />
      ),
    },
    {
      title: "Fourth",
      content: (
        <CuriousJourney
          businessHistoryForm={businessHistoryForm}
          setBusinessHistoryForm={setBusinessHistoryForm}
          usertypeid={usertypeid}
          postingbusinessHistory={postingbusinessHistory}
          />
          ),
          },
          {
            title: "Fifth",
            content: (
              <MoreDetailsAboutYou
              setUserData={setUserData}
              userData={userData}
              usertypeid={usertypeid}
              postinguserOnboardingDetails={postinguserOnboardingDetails}
              userbusinessid={userProfileDetails?.data?.business?.id}
        />
      ),
    },
    {
      title: "Sixth",
      content: (
        <WhoElseOnTeam
          setBusinessContactForm={setBusinessContactForm}
          businessContactForm={businessContactForm}
          usertypeid={usertypeid}
          skip={skip}
        />
      ),
    },
  ];

  const filteredSteps = steps.filter((step) => {
    if (usertypeid === 4) {
      // If usertypeid is 4, exclude the fourth and sixth steps
      return step.title !== "Fourth" && step.title !== "Sixth";
    } else {
      // For other usertypeids, include all steps
      return true;
    }
  });

  return (
    <div className="onboardingbackgimg">
      <Row>
        <Col span={24} className="onboardingcol">
          <Row className="">
            <Col span={24} className="onboardingheader">
              <img alt="nibbleslogo" src={nibbleslogo} />
            </Col>
          </Row>

          <Row>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 12, offset: 6 }}
            >
              {/* <Steps current={current} items={items} /> */}
              <Col>
                {current > 0 ? (
                  <p className="steppercounttext">
                    {current + 1}/{filteredSteps.length}
                  </p>
                ) : (
                  ""
                )}
              </Col>
              <div>{filteredSteps[current]?.content}</div>
              <div className="onbnextandprevbutton">
                {current > 0 && (
                  <Button
                    style={{
                      margin: "0 8px",
                    }}
                    className="onboardingbuttonnextandprev"
                    type="text"
                    onClick={() => prev()}
                  >
                    {"<"} Back
                  </Button>
                )}
                {"."}
                {current === filteredSteps.length - 1 && (
                  <Button
                    className="onboardingbuttonnextandprev"
                    type="text"
                    onClick={handleOnbcompleted}
                  >
                    Done
                  </Button>
                )}
                {current < filteredSteps.length - 1 && (
                  <Button
                    className="onboardingbuttonnextandprev"
                    type="text"
                    onClick={() => next()}
                  >
                    Next {">"}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default OnboardingPage;
