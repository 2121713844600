import { Button, Modal } from 'antd';
import React from 'react'
import { CollectionCreateForm } from './CollectionCreateForm';
import "../../Pages/Dashboard/dashboard.css"

const Status = ({ status, rejected }) => {
    const isApproved = status === 1 && rejected === 0;
    const isRejected = rejected === 1;
    return (
        <div className='d-flex align-items-center gap-2'>
            <div><span>View Application</span></div>
            <div className='d-inline-flex justify-content-center align-items-center' style={{ backgroundColor: isApproved ? "#05964614" : isRejected ? "#FFF2F2" : "#FEF2E5", borderRadius: "20px", padding: "10px 20px" }}>
                <span style={{ color: isApproved ? "#059646" : isRejected ? "#FF404A" : "#E8A010", fontSize: "14px" }}>{isApproved ? "Approved" : isRejected ? "Rejected" : "Pending"}</span>
            </div>
        </div>
    )
}

const ApplicationModal = (props) => {
    const { link, isModalOpen, showModal, initialValues, onCancel, viewApplicationContent, handleApproveApplicant, setFormInstance, open, handleCancel, handleOk, handleOpenRejectModal, selectedTab, user_type_id, handleViewApprovalModal, setLatestDocument, latestDocuments } = props
    return (
        <Modal
            className='application-modal'
            open={open}
            title={<Status status={viewApplicationContent?.status} rejected={viewApplicationContent?.is_rejected} />}
            width={800}
            centered
            styles={{ body: { height: 500, overflowY: "auto", overflowX: "hidden" } }}
            okButtonProps={{
                autoFocus: true,
            }}
            onCancel={onCancel}
            footer={[
                (viewApplicationContent?.status === 0 && viewApplicationContent?.is_rejected !== 1) &&
                <>
                    <Button
                        className="typography rolecancelButton nibbles-button"
                        closable={true}
                        onClick={() => handleOpenRejectModal()}
                    >
                        Reject
                    </Button>,
                    <Button
                        className="typography createproducttbutton nibbles-button"
                        closable={true}
                        onClick={() => handleApproveApplicant(viewApplicationContent?.id)}
                    >
                        Approve
                    </Button>
                </>,
                (viewApplicationContent?.status !== 0 || viewApplicationContent?.is_rejected === 1) && <><Button
                    className="typography rolecancelButton nibbles-butto"
                    closable={true}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                        className="typography createproducttbutton nibbles-button"
                        closable={true}
                        onClick={() => handleApproveApplicant(viewApplicationContent?.id)}
                    >
                        Approve
                    </Button>
                </>
            ]

            }
        >
            <CollectionCreateForm
                viewApplicationContent={viewApplicationContent}
                initialValues={initialValues}
                onFormInstanceReady={(instance) => {
                    setFormInstance(instance);
                }}
                showModal={showModal}
                isModalOpen={isModalOpen}
                handleViewApprovalModal={handleViewApprovalModal}
                handleOk={handleOk}
                handleCancel={handleCancel}
                link={link}
                selectedTab={selectedTab}
                user_type_id={user_type_id}
                setLatestDocument={setLatestDocument}
                latestDocuments={latestDocuments}
            />

        </Modal>
    )
}

export default ApplicationModal