import { CREATE_ORDER, CREATE_ORDER_FAILURE, CREATE_ORDER_SUCCESS, EXPORT_ORDERS, EXPORT_ORDERS_FAILURE, EXPORT_ORDERS_SUCCESS, EXPORT_TRANSACTION_LOGS, EXPORT_TRANSACTION_LOGS_FAILURE, EXPORT_TRANSACTION_LOGS_SUCCESS, GET_ADMIN_ARCHIVE_ORDERS, GET_ADMIN_ARCHIVE_ORDERS_FAILURE, GET_ADMIN_ARCHIVE_ORDERS_SUCCESS, GET_ADMIN_ORDER_BY_ID, GET_ADMIN_ORDER_BY_ID_FAILURE, GET_ADMIN_ORDER_BY_ID_SUCCESS, GET_ALL_ADMIN_ORDERS, GET_ALL_ADMIN_ORDERS_BY_STATUS, GET_ALL_ADMIN_ORDERS_BY_STATUS_FAILURE, GET_ALL_ADMIN_ORDERS_BY_STATUS_SUCCESS, GET_ALL_ADMIN_ORDERS_FAILURE, GET_ALL_ADMIN_ORDERS_SUCCESS, GET_ALL_QUEUE_ORDERS, GET_ALL_QUEUE_ORDERS_FAILURE, GET_ALL_QUEUE_ORDERS_SUCCESS, GET_ALL_TRANSACTION_LOG, GET_ALL_TRANSACTION_LOG_FAILURE, GET_ALL_TRANSACTION_LOG_SUCCESS, GET_ALL_USER_ORDERS, GET_ALL_USER_ORDERS_FAILURE, GET_ALL_USER_ORDERS_SUCCESS, RESET_EXPORT, RESET_ORDERS, SEARCH_ORDERS, SEARCH_ORDERS_FAILURE, SEARCH_ORDERS_SUCCESS, UPDATE_ORDER_STATUS, UPDATE_ORDER_STATUS_FAILURE, UPDATE_ORDER_STATUS_SUCCESS} from "../ActionTypes";

export const getAllAdminOrders = (payload) => ({
    type: GET_ALL_ADMIN_ORDERS,
    payload
});
export const getAllAdminOrdersSuccess = (payload) => ({
    type: GET_ALL_ADMIN_ORDERS_SUCCESS,
    payload
});
// To log out new user
export const getAllAdminOrdersFailure = (payload) => ({
    type: GET_ALL_ADMIN_ORDERS_FAILURE,
    payload
});

export const getAllAdminOrdersByStatusId = (payload) => ({
    type: GET_ALL_ADMIN_ORDERS_BY_STATUS,
    payload
});
export const getAllAdminOrdersByStatusIdSuccess = (payload) => ({
    type: GET_ALL_ADMIN_ORDERS_BY_STATUS_SUCCESS,
    payload
});
// To log out new user
export const getAllAdminOrdersByStatusIdFailure = (payload) => ({
    type: GET_ALL_ADMIN_ORDERS_BY_STATUS_FAILURE,
    payload
});

export const getAllUserOrders = (body, payload) => ({
    type: GET_ALL_USER_ORDERS,
    body,
    payload
});
export const getAllUserOrdersSuccess = (payload) => ({
    type: GET_ALL_USER_ORDERS_SUCCESS,
    payload
});
// To log out new user
export const getAllUserOrdersFailure = (payload) => ({
    type: GET_ALL_USER_ORDERS_FAILURE,
    payload
});

export const getAdminArchiveOrders = (payload) => ({
    type: GET_ADMIN_ARCHIVE_ORDERS,
    payload
});
export const getAdminArchiveOrdersSuccess = (payload) => ({
    type: GET_ADMIN_ARCHIVE_ORDERS_SUCCESS,
    payload
});
// To log out new ARCHIVE
export const getAdminArchiveOrdersFailure = (payload) => ({
    type: GET_ADMIN_ARCHIVE_ORDERS_FAILURE,
    payload
});

export const getAdminOrderById = (payload) => ({
    type: GET_ADMIN_ORDER_BY_ID,
    payload
});
export const getAdminOrderByIdSuccess = (payload) => ({
    type: GET_ADMIN_ORDER_BY_ID_SUCCESS,
    payload
});
// To log out new ARCHIVE
export const getAdminOrderByIdFailure = (payload) => ({
    type:GET_ADMIN_ORDER_BY_ID_FAILURE,
    payload
});

export const updateOrderStatus = (payload) => ({
    type: UPDATE_ORDER_STATUS,
    payload
});
export const updateOrderStatusSuccess = (payload) => ({
    type: UPDATE_ORDER_STATUS_SUCCESS,
    payload
});
// To update state
export const updateOrderStatusFailure = (payload) => ({
    type: UPDATE_ORDER_STATUS_FAILURE,
    payload
});

export const createOrder = (payload) => ({
    type: CREATE_ORDER,
    payload
});
export const createOrderSuccess = (payload) => ({
    type: CREATE_ORDER_SUCCESS,
    payload
});
// To update state
export const createOrderFailure = (payload) => ({
    type: CREATE_ORDER_FAILURE,
    payload
});

export const getAllTransactionLog = (payload) => ({
    type: GET_ALL_TRANSACTION_LOG,
    payload
});
export const getAllTransactionLogSuccess = (payload) => ({
    type: GET_ALL_TRANSACTION_LOG_SUCCESS,
    payload
});
// To log out new user
export const getAllTransactionLogFailure = (payload) => ({
    type: GET_ALL_TRANSACTION_LOG_FAILURE,
    payload
});

export const getAllQueueOrders = (payload) => ({
    type: GET_ALL_QUEUE_ORDERS,
    payload
});
export const getAllQueueOrdersSuccess = (payload) => ({
    type: GET_ALL_QUEUE_ORDERS_SUCCESS,
    payload
});
// To log out new user
export const getAllQueueOrdersFailure = (payload) => ({
    type: GET_ALL_QUEUE_ORDERS_FAILURE,
    payload
});

export const searchOrders = (payload)=>({
    type:SEARCH_ORDERS,
    payload
});
export const searchOrdersSuccess = (payload)=>({
    type:SEARCH_ORDERS_SUCCESS,
    payload
});
export const searchOrdersFailure = (payload)=>({
    type:SEARCH_ORDERS_FAILURE,
    payload
});

export const resetOrders = ()=>({
    type:RESET_ORDERS,
});


export const exportTransactionLogs = (payload)=>({
    type:EXPORT_TRANSACTION_LOGS,
    payload
})
export const exportTransactionLogsSuccess = (payload)=>({
    type:EXPORT_TRANSACTION_LOGS_SUCCESS,
    payload
})
export const exportTransactionLogsFailure = (payload)=>({
    type:EXPORT_TRANSACTION_LOGS_FAILURE,
    payload
})

export const exportOrders = (payload)=>({
    type:EXPORT_ORDERS,
    payload
})
export const exportOrdersSuccess = (payload)=>({
    type:EXPORT_ORDERS_SUCCESS,
    payload
})
export const exportOrdersFailure = (payload)=>({
    type:EXPORT_ORDERS_FAILURE,
    payload
})
export const resetExport = (payload)=>({
    type:RESET_EXPORT,
    payload
})