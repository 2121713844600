import React, { useState } from 'react';
import { Row, Layout, Menu, theme } from 'antd';
// import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import ComplianceForm from '../../Components/compliance/compliance';
import ComNotification from '../../Components/compliance/comNotification';
import ComSecurity from '../../Components/compliance/comSecurity';
import ComBranchSelection from '../../Components/compliance/comBranchSelection';
const { Content, Sider } = Layout;



const ComplianseSettings = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [selectedKeys, setSelectedKeys] = useState('7');
    const handleComMenuSelect = (keys) => {
        // debugger
        setSelectedKeys(keys); // Update selected key when menu item is clicked
    };
    const renderContent = () => {
        switch (selectedKeys) {
            case '7':
                return <ComplianceForm />;
            case '8':
                return <ComNotification />;
            case '9':
                return <ComSecurity />;
                case '10':
                return <ComBranchSelection />;
            default:
                return null;
        }
    };
    return (
        <>
            <Row
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                {/* <Col className="gutter-row" span={24}>
                    <Col className='dashboardtitlescol' span={24}>
                        <p className='dashboardtitlestypog'>
                            Settings
                        </p>
                    </Col>
                </Col> */}
                <Layout style={{
                    background: "#FFF",
                }}
                >
                    <Sider
                        breakpoint="md"
                        collapsedWidth="0"
                        width="260"
                        onBreakpoint={(broken) => {
                            console.log(broken);
                        }}
                        onCollapse={(collapsed, type) => {
                            console.log(collapsed, type);
                        }}
                        style={{
                            minHeight: '100vh',
                            // position: 'fixed',
                            background: "#FFF",
                        }}
                    >
                        <div style={{ textAlign: "start", padding: "2em 2em 0em 2em" }}>
                            <p className='typography fw-bold'>Settings</p>
                            <p className='typography mt-4'>General</p>
                        </div>
                        {/* <div style={{ textAlign: "start", padding: "2em 2em 0em 2em" }}>
                            <p className='typography'>General</p>
                        </div> */}
                        <div style={{ padding: "0 16px" }} />
                        <Menu
                            theme="default"
                            mode="inline"
                            defaultSelectedKeys={['7']}
                            selectedKeys={[selectedKeys]} // Set the selected key
                            onSelect={({ key }) => handleComMenuSelect(key)} // Handle menu item selection
                            style={{ padding: "0em 2em 2em 2em" }}

                        >
                            {/* <div> */}
                            <Menu.Item
                                key="7"
                                style={{ backgroundColor: selectedKeys === "7" ? "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))" : "#FFF", color: selectedKeys === "7" ? "#1B2559" : "#53545C", padding: "2em", gap: "9.167px" }}
                                className='typography'
                            >
                                Compliance
                            </Menu.Item>
                            {/* <Menu.Item
                                key="8"

                                style={{ backgroundColor: selectedKeys === "8" ? "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))" : "#FFF", color: selectedKeys === "8" ? "#1B2559" : "#53545C", padding: "2em", gap: "9.167px" }}
                                className='typography'
                            >
                                Notifications
                            </Menu.Item> */}
                              <Menu.Item
                                key="10"
                                style={{ backgroundColor: selectedKeys === "10" ? "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))" : "#FFF", color: selectedKeys === "10" ? "#1B2559" : "#53545C", padding: "2em", gap: "9.167px" }}
                                className='typography'
                            >
                                Select Branch
                            </Menu.Item>
                            <Menu.Item
                                key="9"
                                style={{ backgroundColor: selectedKeys === "9" ? "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))" : "#FFF", color: selectedKeys === "9" ? "#1B2559" : "#53545C", padding: "2em", gap: "9.167px" }}
                                className='typography'
                            >
                                Security
                            </Menu.Item>
                        </Menu>
                    </Sider>

                    <Layout>
                        <Content
                            style={{
                                // margin: '24px 16px 0',
                                background: "#FFF",
                            }}
                        >
                            <div
                                style={{
                                    padding: 24,
                                    minHeight: 360,
                                    background: colorBgContainer,
                                    borderRadius: borderRadiusLG,
                                }}
                            >
                                {/* content */}
                                {renderContent()}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Row>
        </>
    )
};
export default ComplianseSettings;