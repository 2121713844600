import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form, Select, Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCartReducer } from "../../redux/Reducer/cartReducer";
import {
  getAllAvailableLocation,
  getUserById,
  getUserProfileDetails,
} from "../../redux/Actions";
import { getAuthReducer } from "../../redux/Reducer/authReducer";

const ComBranchSelection = () => {
  const { availableLocations, isloadingAvailableLocations } =
    useSelector(getCartReducer);
  const { userProfileDetails, authUser, userById } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const [defualtbranchsel, setDefaultBranchSel] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleLocationChange = (locationid, locationname) => {
    // debugger
    // console.log(locationid, "getavailloc");
    // console.log(locationname, "getuserbranchlocname");
    setDefaultBranchSel({
      ...defualtbranchsel,
      location_id: locationid,
    });
  };

  const handleDefaultLocationChange = () => {
    // debugger
    // console.log(locationid, "getavailloc");
    // console.log(defualtbranchsel.location_id, "getuserbranchlocid");
    // console.log(userProfileDetails?.data?.user_type_id, "getuserbranchlocname");
    // console.log(userProfileDetails?.data?.id, "userid");
    dispatch(getUserById(userProfileDetails?.data?.id, {location_id: defualtbranchsel.location_id}))
  };

  useEffect(() => {
    dispatch(getUserProfileDetails(authUser?.user.id));
    dispatch(getAllAvailableLocation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Congratulations!!! Nibbles Bakery Pickup Branch was Updated Successfully',
      duration: 10,
    });
  };

  useEffect(() => {
    if (userById?.status === true) {
        success()
        setDefaultBranchSel("")
        dispatch(getUserProfileDetails(authUser?.user.id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userById]);
//   console.log(userById, "userById");

  return (
      <>
      {contextHolder}
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col className="gutter-row" span={24}>
          <Col className="dashboardtitlescol" span={24}>
            <p className="dashboardtitlestypog">
              Select your default nibbles bakery branch
            </p>
          </Col>
        </Col>

        <Col sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 16 }}>
          <Form layout="vertical">
            <Form.Item
              label={
                <label className="typography aboutbusinessformlabel">
                  Please select the dropdown below to change pickup location
                </label>
              }
              className="aboutbusinessformitem"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              {isloadingAvailableLocations ? (
                <Spin size="default" />
              ) : (
                <Select
                  placeholder="Select Branch"
                  defaultValue={userProfileDetails?.data?.location_id}
                  onChange={(value, option) =>
                    handleLocationChange(value, option.label)
                  }
                  options={availableLocations?.map((location) => ({
                    value: location?.id,
                    label: `${location?.name} - ${location?.address}`,
                  }))}
                  value={
                    defualtbranchsel === ""
                      ? userProfileDetails?.data?.location_id
                      : defualtbranchsel.location_id
                  }
                  size="large"
                />
              )}
            </Form.Item>
            <div className="addcompliancelocDiv">
              <Button className="uploadComplianceImage" size="large" onClick={handleDefaultLocationChange} disabled={defualtbranchsel === "" ? true : false} block>
                Change Location
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default ComBranchSelection;
