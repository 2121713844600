import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, Spin, Input, Button } from "antd";
import { exportOrders, getAllAdminOrders, resetExport, resetOrders, searchOrders } from '../../../redux/Actions';
import AvailableOrders from './orderProcessing/availableOrders';
import LoadingOrders from './orderProcessing/loadingOrders';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderReducer } from '../../../redux/Reducer/orderReducer';
import { getAllAdminOrdersByStatusId } from '../../../redux/Actions';
import { ExportIcon, SearchIcon } from '../../../asset/icons/icons';
import { debounce } from 'lodash';
import ExportPopconfirm from '../../../Components/Export/ExportPopconfirm';




// const tag = {
//   padding: "3.867px 7.734px",
//   alignItems: "center",
//   gap: "0px",
//   borderRadius: "348.021px",
//   background: "#F4F1FD",
// }


const AvailableForPickup = () => {
  // eslint-disable-next-line no-unused-vars
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tabKey, setTabKey] = useState('4');
  const { allAdminOrdersByStatusId, isloadingAllAdminOrdersByStatusId, isSearchedOrders, isExportingOrderLog, exportedOrderLogSheet } = useSelector(getOrderReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState([]);

  const handleOpenExport = (newOpen) => {
    setOpenExport(newOpen);
  }
  const handleCloseExport = () => {
    setOpenExport(false);
    setSelectedDates(null);
  };
  const handleDateChange = (dates, dateStrings) => {
    setSelectedDates(dates);
  };

  const onTabKeyChange = (key) => {
    // console.log(key, "Tab key");
    setTabKey(key)
  };

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAdminOrders())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAllAdminOrderByStatusIdApi = () => {
    if (tabKey !== undefined) {
      dispatch(getAllAdminOrdersByStatusId({ "order_status_id": tabKey }))
    } else {

    }
  };


  useEffect(() => {
    if (tabKey !== undefined) {
      dispatch(getAllAdminOrdersByStatusId({ "order_status_id": tabKey }))
    } else {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  useEffect(() => {
    debugger;
    if (allAdminOrdersByStatusId?.data?.length > 0) {
      console.log(allAdminOrdersByStatusId?.data, "data comes to component")
      setFilteredData(allAdminOrdersByStatusId?.data);
      setCurrentPage(1);
      setPageSize(10);
    } else {
      setFilteredData([]);
      setCurrentPage(1);
      setPageSize(10);
    }
  }, [allAdminOrdersByStatusId?.data, isSearchedOrders]);

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log("params", pagination, filters, sorter, extra);
    console.log("params", pagination, filters, sorter, extra);
    // dispatch(getAllSystemAdmin(pagination.current));
    dispatch(getAllAdminOrdersByStatusId({ "order_status_id": tabKey, page: pagination.current }))
  };
  const onShowSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const hasSelected = selectedRowKeys.length > 0;
  const [searchQuery, setSearchQuery] = useState('');
  const [openExport, setOpenExport] = useState(false);
  const [openExportWhenClicked, setOpenExportWhenClicked] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value.length > 0) {
        let payload = {
          searchQuery: value,
          order_status_id: tabKey
        }
        dispatch(searchOrders(payload));
      } else {
        dispatch(resetOrders());
        dispatch(getAllAdminOrdersByStatusId({ order_status_id: tabKey }));
      }
    }, 300),
    [dispatch, tabKey]
  );
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    debouncedSearch(value);
  };
  const handleExport = () => {
    debugger
    if (Array.isArray(selectedDates) && selectedDates.length !== 0) {
      setOpenExportWhenClicked(true)
      let dates = {
        start_date: selectedDates[0]?.format('YYYY-MM-DD'),
        end_date: selectedDates[1]?.format('YYYY-MM-DD'),
        order_status_id: tabKey
      };
      dispatch(exportOrders(dates))

    }
  };
  useEffect(() => {
    debugger
    if (exportedOrderLogSheet) {
      setOpenExportWhenClicked(false)
    }
    return () => {
      resetExport()
    }
  }, [exportedOrderLogSheet])


  const items = [
    {
      key: "4",
      label: (
        <div style={{ color: "#252A4D" }}>
          Loading
          {tabKey === '4' ? <span className="mx-2 tag">
            {allAdminOrdersByStatusId?.meta?.total}
          </span> : ''}
        </div>
      ),
      children: (
        <div className="row mt-4 mb-4">
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys?.length} items` : ""}
          </span>
          <Spin spinning={isloadingAllAdminOrdersByStatusId} tip="Loading...">
            <LoadingOrders
              allAdminOrdersByStatusId={allAdminOrdersByStatusId}
              filteredData={filteredData}
              open={open}
              setOpen={setOpen}
              handleAllAdminOrderByStatusIdApi={handleAllAdminOrderByStatusIdApi}
              onChange={onChange}
              isSearched={isSearchedOrders}
              currentPage={currentPage}
              pageSize={pageSize}
              onShowSizeChange={onShowSizeChange}

            // Pass other necessary props here
            />
          </Spin>
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div style={{ color: "#252A4D" }}>
          Available for pickup
          {tabKey === '5' ? <span className="mx-2 tag">
            {allAdminOrdersByStatusId?.meta?.total}
          </span> : ''}

        </div>
      ),
      children: (
        <div className="row mt-4 mb-4">
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys?.length} items` : ""}
          </span>
          <Spin spinning={isloadingAllAdminOrdersByStatusId} tip="Loading...">
            <AvailableOrders
              allAdminOrdersByStatusId={allAdminOrdersByStatusId}
              filteredData={filteredData}
              open={open}
              setOpen={setOpen}
              handleAllAdminOrderByStatusIdApi={handleAllAdminOrderByStatusIdApi}
              onChange={onChange}
              isSearched={isSearchedOrders}
              currentPage={currentPage}
              pageSize={pageSize}
              onShowSizeChange={onShowSizeChange}


            // Pass other necessary props here
            />
          </Spin>
        </div>
      ),
    },
  ];


  const usermanText = {
    width: '189px',
    flexShrink: 0,
    color: '#45464E',
    fontFamily: '"MADE TOMMY"',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px'
  };


  const exportButton = {
    display: "flex",
    height: "44px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid var(--Gray-100, #E6E7EC)",
    background: "var(--Backgrounds-Background-White, #FFF)",
    // color: "#FFF",
    fontFamily: '"MADE TOMMY"',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    color: "var(--Payaza-Colors-Payaza-Blue-800, #0E2354)",
    marginRight: "5px",
  };

  // const filter = {
  //   color: "var(--Black-80, #52575C)",
  //   fontFamily: "Inter",
  //   fontSize: "13.138px",
  //   fontStyle: "normal",
  //   fontWeight: 500,
  //   lineHeight: "22.992px", // 175%
  //   letterSpacing: "-0.145px",
  // };

  // const filterDiv = {
  //   display: "flex",
  //   width: "588.756px",
  //   justifyContent: "flex-end",
  //   alignItems: "flex-start",
  //   gap: "16.423px",
  //   flexShrink: 0
  // };
  // const filterCount = {
  //   borderRadius: "410.569px",
  //   background: "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))",
  //   display: "flex",
  //   padding: "3.285px 6.138px 4.423px 6.57px",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   color: "var(--Black-80, #52575C)",
  //   textAlign: "center",
  //   fontFamily: "Inter",
  //   fontSize: "9.854px",
  //   fontStyle: "normal",
  //   fontWeight: 400,
  //   lineHeight: "normal"
  // };

  // eslint-disable-next-line no-unused-vars
  const [formValues, setFormValues] = useState();

  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px", margin: "0px" }}>
        <div className="row">
          <div className="d-flex justify-content-start">
            <div className="col">
              <span style={usermanText}>Orders &gt; Available for Pickup</span>
            </div>
            <div className="col d-flex justify-content-end">
              {/* <button onClick={1} style={exportButton}>
                Export
              </button> */}
              <ExportPopconfirm placement="bottomLeft" cancel={handleCloseExport} open={openExport} handleOpenChange={handleOpenExport} onChange={handleDateChange} confirm={handleExport} isLoading={isExportingOrderLog} selectedDates={selectedDates} openWhenClicked={openExportWhenClicked}>
                <Button
                  icon={<ExportIcon />}
                  style={exportButton}
                >
                  Export
                </Button>
              </ExportPopconfirm>

              <pre>{JSON.stringify(formValues, null, 2)}</pre>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          {/* <div className="d-flex justify-content-start">
            <div className="col-3">
              <Input placeholder="Basic usage" />
            </div>
            <div className="col d-flex justify-content-end" style={filterDiv}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M11.9507 10.0641V16.5346C11.9836 16.781 11.9015 17.0437 11.7126 17.2162C11.3924 17.5364 10.8751 17.5364 10.5548 17.2162L8.90433 15.5657C8.71546 15.3768 8.63335 15.1223 8.6662 14.8842V10.0641H8.64156L3.91181 4.00407C3.63262 3.65098 3.69831 3.13367 4.0514 2.85448C4.20742 2.73952 4.37986 2.67383 4.56051 2.67383H16.0564C16.2371 2.67383 16.4095 2.73952 16.5655 2.85448C16.9186 3.13367 16.9843 3.65098 16.7051 4.00407L11.9754 10.0641H11.9507Z"
                    fill="#52575C"
                  />
                </svg>
              </span>{" "}
              <span style={filter} className="">
                Filter
              </span>
              <span style={filterCount}>0</span>
            </div>
          </div> */}
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="col-12 mt-3 col-md-5 col-sm-5">
            <Input
              placeholder={"Search by order id, customer name or location"}
              style={{ width: '100%', border: 'none', color: '#BECOCA', backgroundColor: '#F7F7F7', cursor: "text" }}
              value={searchQuery}
              onChange={handleSearch}
              prefix={<SearchIcon />}
            />
          </div>

          <div className="col-12 mt-3 col-md-5 col-sm-5">
            <span>
              <span className="px-2 order-customer-type-legend-text internal-order-text orderHistory-text"></span><span className='fw-bold' style={{ color: '#328835' }}>Internal Order </span>
              <span className="px-2 order-customer-type-legend-text direct-customer-text orderHistory-text"></span> <span className='fw-bold' style={{ color: '#7f2da5' }}>Direct Customer</span>
              <span className="px-2 order-customer-type-legend-text independent-customer-text orderHistory-text"></span> <span className='fw-bold' style={{ color: '#b9892e' }}>Independent Customer</span>
            </span>
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          className="border mt-2 p-2 overflow-x-hidden"
          items={items}
          onChange={onTabKeyChange}
        />
      </div>
    </>
  );
};


export default AvailableForPickup;
