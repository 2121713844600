import { Avatar, Button, Col, Form, Input, message, Modal, Row, Select, Spin, Upload } from "antd";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { UserOutlined } from "@ant-design/icons";
import AdditionalInfo from "../ApplicationModal/AdditionalInfo";
import moment from "moment";
import { DocumentViewIcon } from "../../asset/icons/icons";
import {
  Facebook,
  InstagramIcon,
  Tiktok,
  TwitterIcon,
} from "../../asset/icons/icons";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useDispatch, useSelector } from "react-redux";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { getCartReducer } from "../../redux/Reducer/cartReducer";
import { getAllAvailableLocation, getLocalGovernmentByStateId, getState } from "../../redux/Actions";
import dayjs from "dayjs";
import { yearsOfExperienceInBreadSales } from "../../Utils/yearRange";
import { LoadScript } from "@react-google-maps/api";
import axios from "axios";
import RenderInputText from "./InputFields/RenderInputText";
import { RenderDateField, RenderNumberField, RenderRadioButton } from "./InputFields/InputFields";

const nonInteractiveStyle = {
  position: "relative",
  display: "inline-block",
  pointerEvents: "none",
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "transparent",
  pointerEvents: "none",
  cursor: "not-allowed",
};

const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const GenerateIcon = ({ icon }) => {
  // debugger;
  if (typeof icon === "string") {
    if (icon.startsWith("http")) {
      return <img src={icon} alt="Social Icon" height={"20px"} />;
    } else {
      switch (icon.toLowerCase()) {
        case "instagram":
          return <InstagramIcon />;
        case "facebook":
          return <Facebook />;
        case "tiktok":
          return <Tiktok />;
        case "twitter":
          return <TwitterIcon />;
        default:
          return null;
      }
    }
  } else {
    return null;
  }
};

//Description: isExempted (exempts the field based on the user id)
//Description: isNotAllowedExclusive (exempts the field based on exclusive)
//Description: isAllowed (exempts the field based on distributors)

export const CustomersForm = ({
  link,
  details,
  initialValues,
  onFormInstanceReady,
  isModalOpen,
  handleCancel,
  handleOk,
  showModal,
  type,
  form,
  open,
  setImage,
  image
}) => {
  const isRetailer = details?.user_type_id === 4;
  const isDistributor = details?.user_type_id === 3;
  const isExclusive = details?.user_type_id === 5;


  const {
    stateData,
    localGovernmentData,
    relationshipTypeData
  } = useSelector(getAuthReducer);
  const { availableLocations } = useSelector(getCartReducer);
  const dispatch = useDispatch();
  const [date, setDate] = useState(null)
  const [showStrategyBrief, setShowStrategyBrief] = useState(false)
  const [options, setOptions] = useState([]);
  const autocompleteServiceRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [messageApi, contextHolder] = message.useMessage();
  // eslint-disable-next-line no-unused-vars
  const [invalidImage, setInvalidImage] = useState(false);

  useEffect(()=>{
    dispatch(getAllAvailableLocation())
    dispatch(getState());
  },[dispatch])

  const handleStateChange = (value, statename, name) => {
    debugger
    if (name === "state" || name === "stateOfOrigin") {
      dispatch(getLocalGovernmentByStateId(value));
    }
    if(name!=="location_id"){
    form.setFieldsValue({
      [name]: statename,
    });
  }else{
    form.setFieldsValue({
      [name]: value,
    });
  }
  };

  const handleDOBChange = (date, dateString) => {
    form.setFieldsValue({
      DOB: dateString,
    });
    if (date) {
      setDate(date);
    } else {
      setDate(null);
    }
  };
  const handleAddressSearch = (value, type) => {
    if (!autocompleteServiceRef.current) {
      autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
    }

    if (value) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions) => {
          const results = predictions
            ? predictions.map((prediction) => ({
              value: prediction.description,
              label: prediction.description,
            }))
            : [];
          setOptions((prevState) => ({
            ...prevState,
            [type]: results,
          }));
        }
      );
    } else {
      setOptions((prevState) => ({
        ...prevState,
        [type]: [],
      }));
    }
  };
  console.log(details,"Please");
  
  const handleAddressChange = (value, type, field) => {
    debugger
    form.setFieldsValue({
      [type]: value,
    });
  };

  const formConfig = [
    {
      sectionTitle: "Company Information",
      row: [
        {
          label: isExclusive ? "Store Name" : "Business / Company Name",
          name: "company_name",
          type: "text",
        },
        {
          label: "Date Joined",
          name: "date_joined",
          type: "text",
          readOnly: true
        },
      ],
    },
    {
      row: [
        {
          label: "Total Orders",
          name: "total_orders",
          type: "text",
          isExempted: details?.user_type_id,
          isNotAllowedExclusive: details?.user_type_id,
          readOnly: true
        },
        {
          label: "Total Amount Spent",
          name: "total_amount_spent",
          type: "text",
          isExempted: details?.user_type_id,
          isNotAllowedExclusive: details?.user_type_id,
          readOnly: true
        },
      ],
    },
    {
      row: [
        {
          label: isRetailer
            ? "Distributor Assigned To"
            : isDistributor
              ? "No. of Retailers Assigned"
              : null,
          name: "assign",
          type: "text",
          readOnly: true
        },
        {
          label: "Nature of Business",
          name: "business_category",
          type: "select",
          placeholder: isRetailer ? "e.g. Retail" : "e.g. Wholesaler",
          isNotAllowedExclusive: details?.user_type_id,
        },
      ],
    },
    {
      row: [
        {
          label: "Country",
          name: "country",
          type: "text",
          readOnly: true,
        },
        {
          label: "State",
          name: "state",
          type: "select",
          placeholder: "a State"
        },
      ],
    },
    {
      row: [
        {
          label: "Local Government",
          name: "localGovernment",
          type: "select",
          placeholder: "an LGA"
        },
        {
          label: isExclusive ? "Store Address" : "Business Address",
          name: "businessAddress",
          type: "address",
        },
        {
          label: "Bakery Pickup Location",
          name: "location_id",
          type: "select",
          value:details?.location_id,
          placeholder: "Bakery of choice",
          isExempted: details?.user_type_id,
          size:24
        },
      ],
    },
    {
      row: [
        {
          label: "Email",
          name: "email",
          type: "text",
        },
        {
          label: isExclusive ? "Store's Phone Number" : "Business Phone Number",
          name: "businessPhoneNumber",
          type: "phoneNumber",
          value: isExclusive ? details?.phone : details?.business?.office_phone,
        }
      ],
    },

    {
      row: [
        {
          label: `CAC Registration Number ${isRetailer ? " (optional)" : ""}`,
          name: "cac_reg_no",
          type: "text",
          isNotAllowedExclusive: details?.user_type_id,
        },
        {
          label: "No of Stores",
          name: "branch",
          type: "text",
          isAllowed: details?.user_type_id,
          isNotAllowedExclusive: details?.user_type_id,
        },
        {
          label: "Years of Experience in Bread Sales",
          name: "year_of_experience",
          type: "select",
          isExempted: details?.user_type_id,
          isNotAllowedExclusive: details?.user_type_id,
          placeholder: "a Range"
        },
      ],
    },
    {
      row: [
        {
          label: "Annual Turnover",
          name: "annualturnover",
          type: "numberFormat",
          isExempted: details?.user_type_id,
          isNotAllowedExclusive: details?.user_type_id,
        },
        
        // {
        //     label: "Minimum Turnover Per Month",
        //     name: "min_turnover_per_month",
        //     type: "text",
        //     isExempted: details?.user_type_id
        // },
      ],
    },
    {
      row: [
        {
          label: "Social Media Handles ",
          name: "socialMediaHandles",
          type: "socials",
        },
      ],
    },
    {
      sectionTitle: details?.user_type_id === 5 ? null : "Personal Information",
      isNotAllowedExclusive: details?.user_type_id,
      row: [
        {
          label: "Full Name (Surname first)",
          name: "fullName",
          type: "text",
          isNotAllowedExclusive: details?.user_type_id,
        },
      ],
    },
    {
      row: [
        {
          label: "Gender",
          name: "gender",
          type: "radio",
          isNotAllowedExclusive: details?.user_type_id,
        },
        {
          label: "Date Of Birth",
          name: "DOB",
          type: "date",
          isNotAllowedExclusive: details?.user_type_id,
        },
      ],
    },
    {
      row: [
        {
          label: "State Of Origin",
          name: "stateOfOrigin",
          type: "select",
          isNotAllowedExclusive: details?.user_type_id,
          placeholder: "a State of Origin"
        },
        {
          label: "Local Goverment of Origin",
          name: "lgaOrigin",
          type: "select",
          isNotAllowedExclusive: details?.user_type_id,
          placeholder: "an LGA"
        },
      ],
    },
    {
      row: [
        {
          label: "Whatsapp Number",
          name: "whatsappNumber",
          type: "phoneNumber",
          value: details?.whatsapp_phone,
          isNotAllowedExclusive: details?.user_type_id,
        },
        {
          label: "Phone Number",
          name: "phoneNumber",
          type: "phoneNumber",
          value: details?.phone,
          isNotAllowedExclusive: details?.user_type_id,
        },
      ],
    },
    {
      row: [
        {
          label: "Resident Address",
          name: "residentAddress",
          type: "address",
          isNotAllowedExclusive: details?.user_type_id,
        },
      ],
    },
    {
      sectionTitle: "Next of Kin Information",
      isExempted: details?.user_type_id,
      isNotAllowedExclusive: details?.user_type_id,

      row: [
        {
          label: "Next Of Kin (Surname first)",
          name: "nextOfKin",
          type: "text",
          isExempted: details?.user_type_id,
          isNotAllowedExclusive: details?.user_type_id,
        },
        {
          label: "Relationship to Next of Kin",
          name: "nextOfKinRelationship",
          type: "select",
          isExempted: details?.user_type_id,
          isNotAllowedExclusive: details?.user_type_id,
        },
      ],
    },
    {
      row: [
        {
          label: "Next of Kin Phone Number",
          name: "nextofKinPhoneNumber",
          type: "text",
          isExempted: details?.user_type_id,
          isNotAllowedExclusive: details?.user_type_id,
        },
        {
          label: "Contact Address of Next of Kin",
          name: "nextOfKinContactAddress",
          type: "address",
          isExempted: details?.user_type_id,
          isNotAllowedExclusive: details?.user_type_id,
        },
      ],
    },
    {
      sectionTitle: "Additional Information",
      isExempted: details?.user_type_id,
      row: [
        {
          label: "Have you worked with us before?",
          name: "is_new_partner",
          type: "extra",
          value: details?.business?.is_new_partner,
          isExempted: details?.user_type_id,
        },
      ],
    },
    {
      row: [
        {
          label:
            "Do you own a suitable delivery van with an insurance policy that meets the bakery requirement’s?",
          name: "has_delivery_van",
          type: "extra",
          value: details?.business?.has_delivery_van,
          isExempted: details?.user_type_id,
        },
      ],
    },
    {
      row: [
        {
          label: "Are you able to distribute a minimum of 100 loaves a week?",
          name: "distribute_loaf",
          type: "extra",
          value: details?.business?.distribute_loaf,
          isExempted: details?.user_type_id,
        },
      ],
    },
    {
      row: [
        {
          label: "Do you do business with other companies?",
          type: "extra",
          name: "otherPartners",
          value: details?.business?.strategy_brief === null ? 0 : 1,
          isExempted: details?.user_type_id,
          extraVal: details?.business?.strategy_brief ?? null,
        },
      ],
    },
    // {
    //     sectionTitle:"Documents",
    //     type:"attachment"
    // },
  ];

  // const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const handleIframeLoad = () => {
    setIsLoading(false);
  };


  useEffect(() => {
    debugger
    if (details && open) {
      if (details?.date_of_birth) {
        const parsedDate = dayjs(details.date_of_birth, "DD/MM/YYYY");
        if (parsedDate.isValid()) {
          setDate(parsedDate); // Set the state only if the date is valid
        } else {
          // console.error("Invalid date format");
        }
      }
      setImage(details?.avatar);
      form.setFieldsValue({
        company_name: details?.business?.name ?? "N/A",
        managers_name: details?.name ?? 'N/A',
        date_joined:
          moment(details?.business?.created_at)?.format("MMM Do YYYY") ?? "N/A",
        total_orders: 0,
        total_amount_spent: 0,
        assign: isRetailer
          ? details?.distributor?.business?.name ?? "None"
          : "0",
        business_category: details?.business?.nature_of_business ?? "N/A",
        country: details?.business?.country ?? "Nigeria",
        state: details?.business?.state ?? "N/A",
        localGovernment: details?.business?.lga ?? "N/A",
        businessAddress: details?.business?.address ?? "N/A",
        email: details?.email ?? "N/A",
        businessPhoneNumber: !isExclusive ? (details?.business?.office_phone ?? "N/A") : (details?.phone ?? "N/A"),
        branch: details?.business?.branch ?? "0",
        cac_reg_no: details?.business?.cac_reg_number ?? "N/A",
        year_of_experience: details?.business?.years_of_experience ?? "N/A",
        annualturnover: `₦ ${details?.business?.annual_turnover}` ?? "N/A",
        location_id:details?.location_id,
        // min_turnover_per_month: `₦ ${details?.business?.min_turnover_per_month}` ?? "N/A",
        ...(details?.socials?.length
          ? details.socials.reduce((acc, social, index) => {
            acc[`socialMediaHandles[${index}].link`] = social.link;
            acc[`socialMediaHandles[${index}].icon`] = social.icon;
            return acc;
          }, {})
          : {
            socialMediaHandlesMessage: `This ${isDistributor
              ? "distributor"
              : isRetailer
                ? "retailer"
                : "direct customer"
              } has no social media handles`,
          }),

        fullName: details?.name ?? "N/A",
        gender: details?.gender ?? "N/A",
        DOB: details?.date_of_birth ?? "N/A",
        stateOfOrigin: details?.state ?? "N/A",
        lgaOrigin: details?.lga ?? "N/A",
        whatsappNumber: details?.whatsapp_phone ?? "N/A",
        phoneNumber: details?.phone ?? "N/A",
        residentAddress: details?.address ?? "N/A",

        nextOfKin: details?.user_kin?.name ?? "N/A",
        nextOfKinRelationship: details?.user_kin?.relationship_type?.name ?? "N/A",
        nextofKinPhoneNumber: details?.user_kin?.phone ?? "N/A",
        nextOfKinContactAddress: details?.user_kin?.address ?? "N/A",
        previousCustomer: "yes" ?? "N/A",
        distribute_loaf: details?.business?.distribute_loaf,
        has_delivery_van: details?.business?.has_delivery_van,
        is_new_partner: details?.business?.is_new_partner,
        strategy_brief: details?.business?.strategy_brief,
        otherPartners: details?.business?.strategy_brief === null ? 0 : 1,
      });
      if (type === "edit") {
        debugger
        dispatch(getLocalGovernmentByStateId(details?.business?.state))
      }
    }
    // onFormInstanceReady(form);

  }, [form, details, isDistributor, isRetailer, isExclusive, type, dispatch, open, setImage]);




  // Get the business documents from the viewApplicationContent
  const documents = details?.business?.documents || [];

  // Create a map to store the latest document for each document type
  const latestDocumentsMap = new Map();

  // Iterate over the documents to populate the map with the latest document for each type
  documents.forEach((doc) => {
    latestDocumentsMap.set(doc.document_type.id, doc);
  });

  // Convert the map values to an array to get the list of latest documents
  const latestDocuments = Array.from(latestDocumentsMap.values());

  const renderModalContent = () => {
    if (!link) return null;
    debugger;
    const fileExtension = link.split(".").pop().toLowerCase();
    if (fileExtension === "pdf") {
      return (
        // <DocViewer
        //   documents={[{ uri: link }]}
        //   pluginRenderers={[PDFRenderer]}
        //   style={{ width: "100%", height: "100vh" }}
        //   disableHeader={true}
        //   disableFileName={true}
        // />
        <Spin spinning={isLoading} tip="Loading...">
          <iframe
            onLoad={handleIframeLoad}
            src={link}
            // src="https://dash.nibblesbakery.ng/"
            style={{
              width: "100%",
              height: "100vh",
              border: "none",
              overflow: "hidden",
              display: isLoading ? "none" : "block",
            }}
            // frameBorder="0"
            title="PDF Viewer"
          ></iframe>
        </Spin>
      );
    } else if (
      ["doc", "docx", "xls", "xlsx", "txt", "ppt", "pptx"].includes(
        fileExtension
      )
    ) {
      return (
        <DocViewer
          documents={[{ uri: link }]}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100vh" }}
          disableHeader={true}
          disableFileName={true}
        />
      );
    } else {
      return (
        <DocViewer
          documents={[{ uri: link }]}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100vh" }}
          disableHeader={true}
          disableFileName={true}
        />
      );
    }
  };



  // const RenderAddressField = ({ colConfig }) => {
  //   return (
  //     <>
  //       {type === "edit" ? <Form.Item

  //         name={colConfig.name}
  //         rules={[{ required: true, message: "Please enter the address of the store" }]}
  //       >
  //         <LoadScript
  //           googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
  //           libraries={["places"]}
  //         >
  //           <Select
  //             showSearch
  //             placeholder="Enter a street address"
  //             type="businessAddress"
  //             onSearch={(value) => handleAddressSearch(value, 'business')}
  //             onChange={(value) => handleAddressChange(value, 'business')}
  //             options={options.business}
  //             filterOption={false}
  //             notFoundContent={null}
  //           />
  //         </LoadScript>
  //       </Form.Item> : <RenderInputText colConfig={colConfig} />}
  //     </>
  //   )
  // }
  const checkFileSize = (file) => {
    const alaterror = () => {
      messageApi.open({
        type: "error",
        content: `${file.name} is not a png, jpg, jpeg or gif file`,
        duration: 10,
      });
    };

    const maxSize = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSize) {
      message.error("File size must be less than 2MB.");
      return true; // Prevent upload
    }
    const allowedFormats = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
    ];
    if (!allowedFormats.includes(file.type)) {
      alaterror();
      setInvalidImage(true);
      return true; // Prevent upload
    } else {
      setInvalidImage(false);
    }
    return false; // Allow upload
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    if (!checkFileSize(file)) {
      onError("File size exceeds limit.");
      return;
    }
  };


  const avatarprops = {
    beforeUpload: checkFileSize,
    customRequest: customRequest,
    maxCount: 1,
    name: "avatar",
    onChange: (info) => {
      console.log(info?.fileList);
      let reader = new FileReader();
      let file = info?.file;

      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "upload_preset",
        process.env.REACT_APP_API_CLOUDINARY_PRESET_KEY
      );
      axios
        .post(
          `${process.env.REACT_APP_API_CLOUDINARY_URL}/${process.env.REACT_APP_API_CLOUDINARY_CLOUD_NAME}/image/upload`,
          formData
        )
        .then((res) => setImage(res.data.secure_url))
        .catch((err) => console.log(err));

      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    },
  };
  return (
    <div className="row">
      <div className="p-4">
        <Form
          layout="vertical"
          form={form}
          name="form_in_modal"
          initialValues={initialValues}
          className="mt-3"
        >
          {/* {isRetailer ? "" : */}
          {/* <div className="row mb-4">
            <div>
              <Avatar
                shape="square"
                size={115}
                icon={
                  details?.avatar ? (
                    <img draggable={false} src={details?.avatar} alt="" />
                  ) : (
                    <UserOutlined />
                  )
                }
              />
            </div>
            {type === "view" ? null : <div>
              <Upload {...avatarprops}>
                <Button className="mt-2 avatar-upload-btn" block>
                  Upload Picture
                </Button>
              </Upload>
            </div>}
          </div> */}
          <div className="row mb-4">
            <div>
              <div>
              <Avatar
                shape="square"
                className="upload-image-avatar"
                size={115}
                icon={image ? <img src={image} alt="" /> : <UserOutlined />}
                style={{backgroundColor:"#F2F4FE"}}
              />
             {type==="view"?null: <div>
                <Upload {...avatarprops}>
                  <Button className="mt-2 avatar-upload-btn" block>
                    Upload Picture
                  </Button>
                </Upload>
              </div>}
              </div>
            </div>
          </div>
          {isExclusive && (
            <Row>
              <Col span={24}>
                <Form.Item
                  style={{ fontWeight: 600 }}
                  label="Manager's Full Name"
                  name="managers_name"
                >
                  <Input
                    placeholder="Social Media Handle Link"
                    // value={details?.name}
                    readOnly={type === "view"}
                    style={{ cursor: type === "view" && "not-allowed" }}
                  />
                </Form.Item>
                {/* } */}
              </Col>
            </Row>
          )}
          {formConfig.map((rowConfig, rowIndex) => (
            <>
              {rowConfig?.sectionTitle &&
                rowConfig.isExempted !== 4 &&
                rowConfig.isExempted !== 5 && (
                  <div className="fs-5 mb-4 mt-3">
                    {rowConfig?.sectionTitle}
                  </div>
                )}

              <Row key={rowIndex} gutter={32}>
                {rowConfig?.row?.map((colConfig, colIndex) =>
                  colConfig.label === null ? null : colConfig.isAllowed ===
                    3 ? null : (
                    <Col
                      span={colConfig?.type === "extra" ? 24 : 12}
                      key={colIndex}
                    >
                      {colConfig?.type === "extra" ? (
                        colConfig.isExempted !== 4 &&
                          colConfig.isExempted !== 5 ? (
                          <>
                            <AdditionalInfo
                              label={colConfig.label}
                              value={colConfig.value}
                              type={type}
                              name={colConfig.name}
                              setShowStrategyBrief={setShowStrategyBrief}
                              showStrategyBrief={showStrategyBrief}
                            />
                            {/* {(colConfig?.extraVal) ? (
                              <Form.Item
                                style={{ fontWeight: 600 }}
                                label="List of other businesses and products involved in:"
                                name="strategy_brief"
                              >
                                <Input.TextArea
                                  placeholder="Businesses and products involved in"
                                  readOnly={type === "view"}
                                  // value={details?.business?.strategy_brief}
                                  onChange={(e) => { }}
                                  rows={5}
                                  style={{ cursor: type === "view" && "not-allowed" }}
                                />
                              </Form.Item>
                            ) : null} */}
                          </>
                        ) : null
                      ) : colConfig?.type === "socials" ? (
                        <>
                          {details?.socials?.length
                            ? details.socials.map((social, index) => {
                              // console.log(social, "Yay");
                              return (
                                <div
                                  key={index}
                                  style={{ cursor: type === "view" && "not-allowed" }}
                                >
                                  <Form.Item
                                    style={{ fontWeight: 500 }}
                                    name={`socialMediaHandles[${index}].link`}
                                    label={`Social Media Handle ${index + 1
                                      } Link`}
                                  >
                                    <Input
                                      placeholder="Social Media Handle Link"
                                      prefix={
                                        <GenerateIcon icon={social?.icon} />
                                      }
                                      readOnly={type === "view"}
                                      style={{ cursor: type === "view" && "not-allowed" }}
                                    />
                                  </Form.Item>
                                </div>
                              );
                            })
                            : colConfig.isExempted === 4 &&
                            colConfig.isExempted === 5 && (
                              <Form.Item
                                style={{ fontWeight: 600 }}
                                name="socialMediaHandlesMessage"
                              >
                                <Input
                                  style={{
                                    backgroundColor: "#E2EBFF",
                                    color: "#252A4D",
                                    cursor: type === "view" && "not-allowed",
                                  }}
                                  disabled
                                  value={`This ${isDistributor
                                    ? "distributor"
                                    : isRetailer
                                      ? "retailer"
                                      : "direct customer"
                                    } has no social media handles`}
                                />
                              </Form.Item>
                            )}
                        </>
                      ) : colConfig.isExempted === 4 ||
                        colConfig.isAllowed === 3 ||
                        colConfig.isNotAllowedExclusive === 5 ? null : (
                        <Form.Item
                          label={colConfig?.label}
                          name={colConfig?.name}
                          style={{
                            fontWeight: 600,
                            ...(colConfig.isAllowed === 3 && {
                              display: "none",
                            }),
                          }}
                        >
                          {colConfig.type === "text" ? (
                            // {user_type_id===5}
                            <Input
                              type={colConfig.type}
                              placeholder=""
                              readOnly={type === "view" || colConfig.readOnly}
                              style={{ cursor: (type === "view" || colConfig.readOnly) && "not-allowed" }}
                            />
                          ) : colConfig.type === "radio" ? (
                            <RenderRadioButton colConfig={colConfig} type={type} />
                          ) :
                            colConfig.type === "date" ? (
                              <RenderDateField colConfig={colConfig} type={type} handleDOBChange={handleDOBChange} date={date} />
                            ) :
                              colConfig.type === "numberFormat" ? (
                                <RenderNumberField colconfig={colConfig} type={type} details={details} />
                              ) :
                                colConfig.type === "address" ? (
                                  type === "view" ? <RenderInputText colConfig={colConfig} type={type} /> :
                                    <>
                                      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={["places"]} />
                                      <Form.Item
                                        name={colConfig.name}
                                        rules={[{ required: true, message: "Please enter the address of the store" }]}
                                      >
                                        <Select
                                          showSearch
                                          placeholder="Enter a street address"
                                          type="businessAddress"
                                          value={form.getFieldValue(colConfig.name)}
                                          onSearch={(value) => handleAddressSearch(value, 'business')}
                                          onChange={(value) => {
                                            form.setFieldsValue({ [colConfig.name]: value });
                                            handleAddressChange(value, colConfig.name);
                                          }}
                                          options={options.business}
                                          filterOption={false}
                                          notFoundContent={null}
                                        />
                                      </Form.Item>

                                    </>

                                )

                                  : colConfig.type === "select" ? (
                                    type === "edit" ? (
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder={`Select ${colConfig?.placeholder}`}
                                        disabled={type === "view"}
                                        onChange={(value, option) => {
                                          handleStateChange(value, option?.label, colConfig.name)
                                        }
                                        }

                                        options={colConfig.name === "state" || colConfig.name === "stateOfOrigin" ? stateData.map((state) => ({
                                          value: state.id,
                                          label: state.name,
                                        })) : (colConfig.name === "nextOfKinRelationship") ?
                                          relationshipTypeData.map(
                                            (relationshiptypes) => ({
                                              value: relationshiptypes.id,
                                              label: relationshiptypes.name,
                                            })
                                          )
                                          : (colConfig.name === "year_of_experience") ?
                                            yearsOfExperienceInBreadSales?.map((year) => ({
                                              value: year.value,
                                              label: year.label
                                            }))
                                            : (colConfig.name === "business_category" && isDistributor) ?
                                              [
                                                {
                                                  value: "Wholesaler",
                                                  label: `Wholesaler`,
                                                },
                                                {
                                                  value: "Distributor",
                                                  label: `Distributor`,
                                                },
                                              ]
                                              : (colConfig.name === "business_category" && isRetailer) ?
                                                [
                                                  {
                                                    value: "Retailer",
                                                    label: `Retailer`,
                                                  },
                                                ]
                                                : (colConfig.name==="location_id")?
                                                availableLocations?.map((location) => ({
                                                  value: location?.id,
                                                  label: `${location?.name}`,
                                                }))
                                                :localGovernmentData.map((localgovernment) => ({
                                                  value: localgovernment.id,
                                                  label: localgovernment.name,
                                                }))}
                                        filterOption={filterOption}

                                      />
                                    ) : <Input
                                      type={colConfig.type}
                                      placeholder=""
                                      readOnly={type === "view" || colConfig.readOnly}
                                      style={{ cursor: (type === "view" || colConfig.readOnly) && "not-allowed" }}
                                    />

                                  ) : (
                                    <div className="phoneInputDiv">
                                      <div style={type === "view" ? nonInteractiveStyle : null}>
                                        <PhoneInput
                                          international
                                          defaultCountry="NG"
                                          autoComplete="tel"
                                          type="tel"
                                          value={colConfig.value}
                                          countryCallingCodeEditable={false}
                                          style={{ cursor: type === "view" && "not-allowed" }}
                                          onChange={(e) => { }}
                                        />
                                        {type === "view" && <div style={overlayStyle} />}
                                      </div>
                                    </div>
                                  )}
                        </Form.Item>
                      )}
                    </Col>
                  )
                )}
              </Row>
            </>
          ))}

          {/* Document starts */}
          {isDistributor && (
            <div>
              <div className="fs-5 mb-4 mt-3">Documents</div>
              {latestDocuments.length !== 0 &&
                latestDocuments.map((item, i) => (
                  <div
                    key={i}
                    className="uploadcaccard row mb-4"
                    style={{
                      ...(latestDocuments.length === 0 && {
                        backgroundColor: "#E2EBFF",
                      }),
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div>
                        <div className="d-flex justify-content-between align-items-center w-full" style={{ marginLeft: "0.1em", }}>
                          <div
                            style={{ color: "#000", width: "100%" }}
                            className="mt-2 mb-0 typography d-flex justify-content-between w-full"
                          >
                            <div className="d-flex align-items-center gap-2">
                              <DocumentViewIcon />
                              <div>
                                <div key={i}>
                                  <div>{item?.document_type?.name}</div>
                                </div>
                                <div>
                                  <Button
                                    type="text"
                                    onClick={() => showModal(item.url)}
                                    style={{ color: "#1347F5" }}
                                    className="mt-0 typography p-0"
                                  >
                                    View Document
                                  </Button>
                                  <Modal
                                    title="Compliance Document"
                                    open={isModalOpen}
                                    onOk={handleOk}
                                    onCancel={handleCancel}
                                    width={700}
                                    okButtonProps={{ hidden: true }}
                                  >
                                    {renderModalContent()}
                                  </Modal>
                                </div>
                              </div>
                            </div>
                            <div>
                              {/* {type === "edit" ?
                            <Tooltip title="Replace Document">
                              <Button style={{ backgroundColor: "#282c34" }} size="large" type="primary" shape="circle" icon={<UploadOutlined />}></Button>

                            </Tooltip>
                            : null} */}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {details?.business?.documents?.length === 0 && (
                <Input
                  style={{ backgroundColor: "#E2EBFF", color: "#252A4D" }}
                  disabled
                  value={"This distributor has not uploaded any document"}
                />

              )}
            </div>
          )}

          {/**end of document */}
        </Form>
      </div>
    </div>
  );
};
