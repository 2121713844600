import { Avatar, Button, Form, Input, Modal, Popconfirm, Spin } from "antd";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { UserOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import AdditionalInfo from "./AdditionalInfo";
import { DocumentViewIcon } from "../../asset/icons/icons";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { getAuthReducer } from "../../redux/Reducer/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { deleteApplicantDocument} from "../../redux/Actions";

const nonInteractiveStyle = {
  position: "relative",
  display: "inline-block",
  pointerEvents: "none",
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "transparent",
  pointerEvents: "none",
  cursor: "not-allowed",
};

export const CollectionCreateForm = ({
  link,
  initialValues,
  onFormInstanceReady,
  viewApplicationContent,
  isModalOpen,
  handleCancel,
  handleOk,
  showModal,
  // viewAppModalStatus,
  // selectedTab,
  // user_type_id,
  // handleViewApprovalModal,
  setLatestDocument,
  latestDocuments
}) => {
  const readOnly = true;
  const formConfig = [
    {
      sectionTitle: "Company Information 🏢",
      row: [
        {
          label: "Business / Company Name",
          name: "company_name",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "Nature of Business",
          name: "business_category",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "Country",
          name: "country",
          type: "text",
        },
        {
          label: "State",
          name: "state",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "Local Government",
          name: "localGovernment",
          type: "text",
        },
        {
          label: "Business Address",
          name: "businessAddress",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "Email",
          name: "email",
          type: "text",
        },
        {
          label: "Business Phone Number",
          name: "businessPhoneNumber",
          type: "phoneNumber",
          value: viewApplicationContent?.business?.office_phone,
        },
      ],
    },
    {
      row: [
        {
          label: "CAC Registration Number",
          name: "cac_reg_no",
          type: "text",
        },
        {
          label: "Years of Experience in Bread Sales",
          name: "year_of_experience",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "Annual Turnover",
          name: "annualturnover",
          type: "text",
        },
        {
          label: "Minimum Turnover Per Month",
          name: "min_turnover_per_month",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "Social Media Handles ",
          name: "socialMediaHandles",
          type: "socials",
        },
      ],
    },
    {
      sectionTitle: "Personal Information 👤",
      row: [
        {
          label: "Full Name (Surname first)",
          name: "fullName",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "Gender",
          name: "gender",
          type: "text",
        },
        {
          label: "Date Of Birth",
          name: "DOB",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "State Of Origin",
          name: "stateOfOrigin",
          type: "text",
        },
        {
          label: "Local Goverment of Origin",
          name: "lgaOrigin",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "Whatsapp Number",
          name: "whatsappNumber",
          type: "phoneNumber",
          value: viewApplicationContent?.whatsapp_phone,
        },
        {
          label: "Phone Number",
          name: "phoneNumber",
          type: "phoneNumber",
          value: viewApplicationContent?.phone,
        },
      ],
    },
    {
      row: [
        {
          label: "Resident Address",
          name: "residentAddress",
          type: "text",
        },
      ],
    },
    {
      sectionTitle: "Next of Kin Information 👥",
      row: [
        {
          label: "Next Of Kin (Surname first)",
          name: "nextOfKin",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "Relationship to Next of Kin",
          name: "nextOfKinRelationship",
          type: "text",
        },
        {
          label: "Next of Kin Phone Number",
          name: "nextofKinPhoneNumber",
          type: "text",
        },
      ],
    },
    {
      row: [
        {
          label: "Contact Address of Next of Kin",
          name: "nextOfKinContactAddress",
          type: "text",
        },
      ],
    },
    {
      sectionTitle: "Additional Information 📝",
      row: [
        {
          label: "Have you worked with us before?",
          name: "previousCustomer",
          type: "extra",
          value: viewApplicationContent?.business?.is_new_partner,
        },
      ],
    },

    {
      row: [
        {
          label:
            "Do you own a suitable delivery van with an insurance policy that meets the bakery requirement’s?",
          // name: "previousCustomer",
          type: "extra",
          value: viewApplicationContent?.business?.has_delivery_van,
        },
      ],
    },
    {
      row: [
        {
          label: "Are you able to distribute a minimum of 100 loaves a week?",
          // name: "previousCustomer",
          type: "extra",
          value: viewApplicationContent?.business?.distribute_loaf,
        },
      ],
    },
    {
      row: [
        {
          label: "Do you do business with other companies?",
          type: "extra",
          value:
            viewApplicationContent?.business?.strategy_brief === null ? 0 : 1,
          // isExempted: viewApplicationContent?.user_type_id,
          extraVal: viewApplicationContent?.business?.strategy_brief ?? null,
        },
      ],
    },
    {
      sectionTitle: "Other representative 📞",
      optional: viewApplicationContent?.business?.contact_name === null,
      row: [
        {
          label: "Name of Representative",
          name: "contact_name",
          type: "text",
          value: viewApplicationContent?.business?.contact_name,
          optional: viewApplicationContent?.business?.contact_name === null,
        },
      ],
    },
    {
      row: [
        {
          label: "Email of Representative",
          name: "contact_email",
          type: "text",
          value: viewApplicationContent?.business?.contact_email,
          optional: viewApplicationContent?.business?.contact_email === null,
        },
      ],
    },
    {
      row: [
        {
          label: "Phone Number of Representative",
          name: "contact_phone",
          type: "phoneNumber",
          value: viewApplicationContent?.business?.contact_phone,
          optional: viewApplicationContent?.business?.contact_phone === null,
        },
      ],
    },
  ];

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const {
    deleteApplicantDoc,
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const handleIframeLoad = () => {
    setIsLoading(false);
  };
  console.log(viewApplicationContent, "viewApplicationContent details");
  useEffect(() => {
    if (viewApplicationContent) {
      form.setFieldsValue({
        company_name: viewApplicationContent?.business?.name ?? "N/A",
        business_category:
          viewApplicationContent?.business?.nature_of_business ?? "N/A",
        country: viewApplicationContent?.business?.country ?? "N/A",
        state: viewApplicationContent?.business?.state ?? "N/A",
        localGovernment: viewApplicationContent?.business?.lga ?? "N/A",
        businessAddress: viewApplicationContent?.business?.address ?? "N/A",
        email: viewApplicationContent?.email ?? "N/A",
        businessPhoneNumber:
          viewApplicationContent?.business?.office_phone ?? "N/A",
        cac_reg_no: viewApplicationContent?.business?.cac_reg_number ?? "N/A",
        year_of_experience:
          viewApplicationContent?.business?.years_of_experience ?? "N/A",
        annualturnover:
          `₦ ${viewApplicationContent?.business?.annual_turnover}` ?? "N/A",
        min_turnover_per_month:
          `₦ ${viewApplicationContent?.business?.min_turnover_per_month}` ??
          "N/A",
        ...(viewApplicationContent?.socials?.length
          ? viewApplicationContent.socials.reduce((acc, social, index) => {
              acc[`socialMediaHandles[${index}].link`] = social.link;
              acc[`socialMediaHandles[${index}].icon`] = social.icon;
              return acc;
            }, {})
          : {
              socialMediaHandlesMessage:
                "This distributor has no social media handles",
            }),

        fullName: viewApplicationContent?.name ?? "N/A",
        gender: viewApplicationContent?.gender ?? "N/A",
        DOB: viewApplicationContent?.date_of_birth ?? "N/A",
        stateOfOrigin: viewApplicationContent?.state ?? "N/A",
        lgaOrigin: viewApplicationContent?.lga ?? "N/A",
        whatsappNumber: viewApplicationContent?.whatsapp_phone ?? "N/A",
        phoneNumber: viewApplicationContent?.phone ?? "N/A",
        residentAddress: viewApplicationContent?.address ?? "N/A",

        nextOfKin: viewApplicationContent?.user_kin?.name ?? "N/A",
        nextOfKinRelationship:
          viewApplicationContent?.user_kin?.relationship_type_id ?? "N/A",
        nextofKinPhoneNumber: viewApplicationContent?.user_kin?.phone ?? "N/A",
        nextOfKinContactAddress:
          viewApplicationContent?.user_kin?.address ?? "N/A",
        contact_name: viewApplicationContent?.business?.contact_name,
        contact_email: viewApplicationContent?.business?.contact_email,
        contact_phone: viewApplicationContent?.business?.contact_phone,
      });
    }
    onFormInstanceReady(form);
  }, [form, viewApplicationContent, onFormInstanceReady]);

  // Get the business documents from the viewApplicationContent
  const documents = viewApplicationContent?.business?.documents || [];

  // Create a map to store the latest document for each document type
  const latestDocumentsMap = new Map();

  // Iterate over the documents to populate the map with the latest document for each type
  documents.forEach((doc) => {
    latestDocumentsMap.set(doc.document_type.id, doc);
  });

  // Convert the map values to an array to get the list of latest documents
  // const latestDocuments = Array.from(latestDocumentsMap.values());

  

  // useEffect(() => {
  //   // debugger;
  //   // if (documents) {
  //     setLatestDocument(Array.from(latestDocumentsMap.values()))
  //   // } else {
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const renderModalContent = () => {
    if (!link) return null;
    // debugger;
    const fileExtension = link.split(".").pop().toLowerCase();
    if (fileExtension === "pdf") {
      return (
        // <DocViewer
        //   documents={[{ uri: link }]}
        //   pluginRenderers={[PDFRenderer]}
        //   style={{ width: "100%", height: "100vh" }}
        //   disableHeader={true}
        //   disableFileName={true}
        // />
        <Spin spinning={isLoading} tip="Loading..." className="mt-4">
          <iframe
            onLoad={handleIframeLoad}
            src={link}
            // src="https://dash.nibblesbakery.ng/"
            style={{
              width: "100%",
              height: "100vh",
              border: "none",
              overflow: "hidden",
              display: isLoading ? "none" : "block",
            }}
            // frameBorder="0"
            title="PDF Viewer"
          ></iframe>
        </Spin>
      );
    } else if (
      ["doc", "docx", "xls", "xlsx", "txt", "ppt", "pptx"].includes(
        fileExtension
      )
    ) {
      return (
        <DocViewer
          documents={[{ uri: link }]}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100vh" }}
          disableHeader={true}
          disableFileName={true}
        />
      );
    } else {
      return (
        <DocViewer
          documents={[{ uri: link }]}
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100vh" }}
          disableHeader={true}
          disableFileName={true}
        />
      );
    }
  };

  const handleDeleteDocument = (itemId) => {
    // debugger
    const updateddocuments = latestDocuments?.filter((item) => item?.id !== itemId);
    dispatch(deleteApplicantDocument(itemId));
    console.log("latest doc", updateddocuments);
    console.log("latest doc", itemId);
    setLatestDocument(updateddocuments);
  };

  useEffect(() => {
    // debugger;
    if (deleteApplicantDoc === "") {
      // setLatestDocument(documents)
      // if (selectedTab === "1") {
      //   dispatch(getAllIndependentDistributorship({ user_type_id }));
      // } else if (selectedTab === "2") {
      //   dispatch(getPendingApplication());
      // } else if (selectedTab === "3") {
      //   dispatch(getApprovedApplication());
      // } else {
      //   dispatch(getRejectedApplication());
      // }
      // handleViewApprovalModal()
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteApplicantDoc]);
  
  console.log('deletedrequestans', deleteApplicantDoc)
  
  
  return (
    <div className="row">
      <div className="col-8">
        <Form
          layout="vertical"
          form={form}
          name="form_in_modal"
          initialValues={initialValues}
          className="mt-3"
        >
          {formConfig.map((rowConfig, rowIndex) => (
            <>
              {rowConfig?.sectionTitle && (
                <div className="fs-5 mb-4 mt-3">
                  {rowConfig?.sectionTitle && !rowConfig?.optional}
                </div>
              )}
              <div className="row" key={rowIndex} style={{}}>
                {rowConfig?.row?.map((colConfig, colIndex) =>
                  colConfig?.optional === true ? null : (
                    <div className="col" key={colIndex}>
                      {colConfig?.type === "extra" ? (
                        <>
                          <AdditionalInfo
                            label={colConfig.label}
                            value={colConfig.value}
                          />
                          {colConfig?.extraVal ? (
                            <Form.Item
                              style={{ fontWeight: 600 }}
                              label="List of other businesses and products involved in:"
                            >
                              <Input.TextArea
                                placeholder="Social Media Handle Link"
                                readOnly
                                style={{ cursor: "not-allowed" }}
                                value={
                                  viewApplicationContent?.business
                                    ?.strategy_brief
                                }
                                rows={5}
                              />
                            </Form.Item>
                          ) : null}
                        </>
                      ) : colConfig?.type === "socials" ? (
                        <>
                          {viewApplicationContent?.socials?.length ? (
                            viewApplicationContent.socials.map(
                              (social, index) => {
                                console.log(social, "Yay");
                                return (
                                  <div key={index}>
                                    <Form.Item
                                      style={{ fontWeight: 600 }}
                                      name={`socialMediaHandles[${index}].link`}
                                      label={`Social Media Handle ${
                                        index + 1
                                      } Link`}
                                    >
                                      <Input
                                        placeholder="Social Media Handle Link"
                                        prefix={
                                          <img
                                            src={social?.icon}
                                            height={"20px"}
                                            alt=""
                                          />
                                        }
                                        readOnly
                                        style={{ cursor: "not-allowed" }}
                                      />
                                    </Form.Item>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <Form.Item
                              style={{ fontWeight: 600 }}
                              name="socialMediaHandlesMessage"
                            >
                              <Input
                                style={{
                                  backgroundColor: "#E2EBFF",
                                  color: "#252A4D",
                                }}
                                disabled
                                value="This distributor has no social media handles"
                              />
                            </Form.Item>
                          )}
                        </>
                      ) : (
                        <Form.Item
                          style={{ fontWeight: 600 }}
                          label={colConfig.label}
                          name={colConfig.name}
                        >
                          {colConfig.type === "text" ? (
                            <Input
                              type={colConfig.type}
                              placeholder=""
                              readOnly={true}
                              style={{ cursor: "not-allowed" }}
                            />
                          ) : (
                            <div className="phoneInputDiv">
                              <div style={nonInteractiveStyle}>
                                <PhoneInput
                                  international
                                  defaultCountry="NG"
                                  autoComplete="tel"
                                  type="tel"
                                  value={colConfig.value}
                                  countryCallingCodeEditable={false}
                                  style={{ cursor: "not-allowed" }}
                                />
                                {readOnly && <div style={overlayStyle} />}
                              </div>
                            </div>
                          )}
                        </Form.Item>
                      )}
                    </div>
                  )
                )}
              </div>
            </>
          ))}

          {/* Personal information ends */}
        </Form>
      </div>
      <div className="col-4" style={{ margin: "80px 0px" }}>
        <Avatar
          shape="square"
          size={180}
          style={{
            backgroundColor: "#F5F8FF",
            border: "none",
            borderRadius: "12px",
          }}
          icon={
            viewApplicationContent?.avatar !== null ? (
              <img
                draggable={false}
                src={viewApplicationContent?.avatar}
                alt=""
              />
            ) : (
              <div>
                <UserOutlined style={{ color: "#252A4D" }} />
                <div>
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#1a1a1a",
                    }}
                  >
                    No Image Uploaded
                  </p>
                </div>
              </div>
            )
          }
        />
      </div>

      <div className="fs-5 mb-4 mt-3">Documents 📃</div>
      {latestDocuments.length !== 0 &&
        latestDocuments.map((item, i) => (
          <div
            key={i}
            className="uploadcaccard row mb-4"
            style={{
              ...(latestDocuments.length === 0 && {
                backgroundColor: "#E2EBFF",
              }),
            }}
          >
            <div className="col-8">
              <div className="innerUploadcacdiv">
                <div style={{ marginLeft: "2em" }}>
                  <p style={{ color: "#000" }} className="mt-2 mb-0 typography">
                    <div className="d-flex align-items-center gap-2">
                      <DocumentViewIcon />
                      <div>
                        <div key={i}>
                          <div>{item?.document_type?.name}</div>
                        </div>
                        <div>
                          <Button
                            type="text"
                            onClick={() => showModal(item.url)}
                            style={{ color: "#1347F5" }}
                            className="mt-0 typography p-0"
                          >
                            View Document
                          </Button>
                          <Modal
                            title="Compliance Document"
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            width={800}
                            okButtonProps={{ hidden: true }}
                          >
                            {/* <div
                              style={{
                                overflowY: "hidden",
                                height: "350px",
                                paddingBottom: "30px",
                              }}
                            > */}
                            {/* <img
                                src={link}
                                alt="Uploaded file"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                                className="mt-3 fileiconcac"
                              /> */}
                            {/* <DocViewer
                                documents={[{ uri: link }]}
                                pluginRenderers={DocViewerRenderers}
                                style={{ width: "100%", height: "100vh" }}
                                disableHeader={true}
                                disableFileName={true}
                              /> */}
                            {renderModalContent()}
                            {/* </div> */}
                          </Modal>
                        </div>
                      </div>

                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-1">
              {/* <Button
                // type="primary"
                danger
                icon={<DeleteOutlined />}
                // loading={loadings[2]}
                onClick={() => handleDeleteDocument(item?.id)}
              /> */}
              <Popconfirm
                title="Delete Document"
                description="Are you sure to delete this document?"
                onConfirm={() => handleDeleteDocument(item?.id)}
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: 'red',
                    }}
                  />
                }            
                onOpenChange={() => console.log('open change')}
                okText="Yes"
                cancelText="No"
              >
                <Button
                // type="primary"
                danger
                icon={<DeleteOutlined />}
              />
              </Popconfirm>
            </div>
          </div>
        ))}
      {viewApplicationContent?.business?.documents?.length === 0 && (
        <div style={{ padding: "0px 20px 0px 20px" }}>
          <Input
            style={{ backgroundColor: "#E2EBFF", color: "#252A4D" }}
            disabled
            value={"This distributor has not uploaded any document"}
          />
        </div>
      )}
    </div>
  );
};
