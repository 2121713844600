import React, { useEffect, useState } from "react";
import { Button, Spin, Table, Modal } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getOrderReducer } from "../../redux/Reducer/orderReducer";
import { getAdminOrderById, getAllUserOrders } from "../../redux/Actions";
import { FormatNumber } from "../../Utils/cartmgt";
import MyPendingOrders from "../../Components/MyPendingOrders";
import noorderhistory from "../../asset/noorderhistory.png";

const OrderHistoryDashboard = (props) => {
  const [open, setOpen] = useState(false);
  const { authuserdetails } = props;
  const {
    allUserOrders,
    isloadingAllUserOrders,
    adminOrderById,
    isloadingAdminOrderById,
  } = useSelector(getOrderReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUserOrders(authuserdetails?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log("params", pagination, filters, sorter, extra);
    console.log("params", pagination, filters, sorter, extra);
    // dispatch(getAllSystemAdmin(pagination.current));
    dispatch(getAllUserOrders(authuserdetails?.id, pagination.current));
  };

  const handleShowUserOrderDetails = (orderid) => {
    // console.log(orderid,"orderid")
    dispatch(getAdminOrderById(orderid));
    // debugger
    setOpen(true); // Update selected key when menu item is clicked
  };

  const columns = [
    {
      title: "ORDER ID",
      dataIndex: "id",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Transaction Reference",
      dataIndex: "reference",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Date",
      dataIndex: "start_date",
      render: (text) => (
        <span className="orderHistory-text">
          {moment(text).format("MMM Do YYYY")} <br />{" "}
          <span className="orderHistory-time">
            At {moment(text).format("h:mm A")}
          </span>
        </span>
      ),
      sorter: (a, b) => a.start_date?.localeCompare(b?.start_date),
    },
    {
      title: "Total No Of Items",
      dataIndex: "qty",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.qty - b.qty,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => (
        <span className="orderHistory-text">
          {text === undefined || null ? "N/A" : FormatNumber(text)}
        </span>
      ),
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Status",
      dataIndex: "order_status",
      render: (text, record) => {
        let progressClass = "";
        switch (text.id) {
          case 1:
            progressClass = "orderHistory in-progress";
            break;
          case 2:
            progressClass = "orderHistory completed";
            break;
          case 6:
            progressClass = "orderHistory rejected";
            break;
          case 0:
            progressClass = "orderHistory rejected";
            break;
          default:
            progressClass = "orderHistory completed";
        }
        return (
          <span key={text.id} className={progressClass}>
            {text.name === "Initial" ? "Incoming" : text.name}
          </span>
        );
      },
      sorter: (a, b) =>
        a.order_status.name?.localeCompare(b?.order_status.name),
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <Button
            type="text"
            onClick={() => handleShowUserOrderDetails(text)}
            className="orderHistory-view-details"
          >
            View Details
          </Button>
        </>
      ),
    },
  ];

  // console.log(adminOrderById, "Azzzgard")
  return (
    <>
      <div className="row">
        <div className="col"></div>
        <div className="col"></div>
      </div>
      <div className="row mt-5 mb-4">
        <Spin spinning={isloadingAllUserOrders} tip="Loading...">
          {allUserOrders && allUserOrders?.data?.length !== 0 ? (
            <Table
              columns={columns}
              dataSource={allUserOrders?.data}
              onChange={onChange}
              scroll={{ x: 1000 }}
              pagination={{
                pageSize: allUserOrders?.meta?.per_page,
                showSizeChanger: true,
                pageSizeOptions: ["10", "15", "20", "30"],
                showQuickJumper: true,
                total: allUserOrders?.meta?.total,
                style: {
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                },
              }}
            />
          ) : (
            <p style={{ textAlign: "center" }}>
              <img
                src={noorderhistory}
                alt="no order history"
                height={"260px"}
                draggable={false}
              />
            </p>
          )}
        </Spin>
      </div>

      <Modal
        title="Order Details"
        // centered
        style={{
          top: 20,
        }}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <Spin spinning={isloadingAdminOrderById} tip="Loading...">
          <MyPendingOrders orderDetailsData={adminOrderById} />
        </Spin>
      </Modal>
    </>
  );
};
export default OrderHistoryDashboard;
