import React, { useState } from "react";
import { Table, Button } from "antd";
import moment from "moment";
import { FormatNumber } from "../../../../Utils/cartmgt";
import ViewOrderDetailsModal from "../../../../Components/OrdersAdmin/ViewOrderDetails";

// const onChange = (pagination, filters, sorter, extra) => {
//   console.log("params", pagination, filters, sorter, extra);
// };

const orderRejectedError = "";
const orderStatus = 4;
const initialStage = false;
const processingStage = false;
const loadingStage = false;
const pickUpStage = true;
const orderRejected = false;
const isArchived = false;

const AvailableOrders = ({
  allAdminOrdersByStatusId,
  filteredData,
  rowSelection,
  onChange,
  handleAllAdminOrderByStatusIdApi,
  isSearched,
  currentPage,
  pageSize,
  onShowSizeChange, 
}) => {
  const [open, setOpen] = useState(false);
  const [pickupOrderId, setpickupOrderId] = useState();

  const handleOrderAction = (value) => {
    // debugger
    setOpen(true);
    setpickupOrderId(value);
    // console.log(value)
  };

  // eslint-disable-next-line no-unused-vars
  const [formValues, setFormValues] = useState();

  const onCreate = (values) => {
    // console.log('Received values of form: ', values);
    setFormValues(values);
    setOpen(false);
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      render: (text) => <span className="orderHistory-text">{text ?? ''}</span>,
      sorter: (a, b) => a?.id - b?.id,
    },
    {
      title: "Transanction Reference",
      dataIndex: "reference",
      render: (text, record) => (
        <span className="orderHistory-text">
          {record?.is_internal_order ? <span className="px-2 order-customer-type-text internal-order-text"></span> : ""}
          {text ?? ''}
        </span>
      ),
      sorter: (a, b) => a?.reference?.localeCompare(b?.reference),
    },
    {
      title: "Customer",
      dataIndex: "user",
      render: (text, record) => (
        <span className="orderHistory-text">
          {text?.user_type?.id === 5 ?
            <span className="px-2 order-customer-type-legend-text direct-customer-text"></span> 
            : 
            record?.is_internal_order === 1 ?
            <span className="px-2 order-customer-type-legend-text internal-order-text"></span> 
            :
            <span className="px-2 order-customer-type-text independent-customer-text">
            </span>
            }
          {text?.name ?? ''} <br />
        </span>
      ),
      sorter: (a, b) => a?.user?.name?.localeCompare(b?.user?.name),
    },
    {
      title: "Date of Order",
      dataIndex: "created_at",
      render: (text) => (
        <span className="orderHistory-text">
          {moment(text)?.format("MMM Do YYYY")}
        </span>
      ),
      sorter: (a, b) => a?.created_at?.localeCompare(b?.created_at),
    },
    {
      title: "Time of Order",
      dataIndex: "created_at",
      render: (text) => (
        <span className="orderHistory-text">
          {moment(text)?.format("h:mm A")}
        </span>
      ),
      sorter: (a, b) => a?.created_at?.localeCompare(b?.created_at),
    },
    {
      title: "Branch",
      dataIndex: "location",
      render: (text) => <span className="orderHistory-text">{text?.name ?? ''}</span>,
      sorter: (a, b) => a?.location.name?.localeCompare(b?.location.name),
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      render: (text, record) => {
        let statusClass = "";
        switch (text?.id) {
          case 1:
            statusClass = "orderHistory received";
            break;
          case 2:
            statusClass = "orderHistory confirmed";
            break;
          case 3:
            statusClass = "orderHistory processing";
            break;
          case 4:
            statusClass = "orderHistory rejected";
            break;
          default:
            statusClass = "orderHistory approved";
        }
        return (
          <span key={text?.id} className={statusClass} style={{ width: "100%" }}>
            {text?.name === "Pick-up" ? "Available for pickup" : text?.name}
          </span>
        );
      },
      sorter: (a, b) =>
        a?.order_status.name?.localeCompare(b?.order_status.name),
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      render: (text) => (
        <span className="orderHistory-text">
          {text === undefined || null ? "N/A" : FormatNumber(text)}
        </span>
      ),
      sorter: (a, b) => a?.amount - b?.amount,
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (text, record) => (
        <Button
          type="text"
          onClick={() => handleOrderAction(text)}
          className="orderHistory-view-details"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
          >
            <path
              d="M8.3198 0.504883C10.0075 0.504883 11.6075 1.0904 13.0007 2.1744C14.3938 3.25048 15.5799 4.82504 16.4317 6.79523C16.4954 6.94556 16.4954 7.11964 16.4317 7.26206C14.7281 11.2024 11.6951 13.5603 8.3198 13.5603H8.31184C4.9445 13.5603 1.91149 11.2024 0.20792 7.26206C0.144235 7.11964 0.144235 6.94556 0.20792 6.79523C1.91149 2.85486 4.9445 0.504883 8.31184 0.504883H8.3198ZM8.3198 3.86765C6.5605 3.86765 5.13555 5.28396 5.13555 7.0326C5.13555 8.77332 6.5605 10.1896 8.3198 10.1896C10.0711 10.1896 11.4961 8.77332 11.4961 7.0326C11.4961 5.28396 10.0711 3.86765 8.3198 3.86765ZM8.32076 5.05276C9.41136 5.05276 10.303 5.93895 10.303 7.03086C10.303 8.11486 9.41136 9.00104 8.32076 9.00104C7.22219 9.00104 6.3306 8.11486 6.3306 7.03086C6.3306 6.89635 6.34652 6.76975 6.3704 6.64315H6.41021C7.29384 6.64315 8.01029 5.94686 8.04214 5.0765C8.1297 5.06068 8.22523 5.05276 8.32076 5.05276Z"
              fill="#45464E"
            />
          </svg>
        </Button>
      ),
    },
  ];

  return (
    <div className="row mt-4 mb-4">
      <Table
        rowSelection={rowSelection}
        columns={columns}
        scroll={{ x: 1000 }}
        // dataSource={allAdminOrdersByStatusId?.data}
        dataSource={filteredData}
        onChange={onChange}
        // pagination={{
        //   pageSize: allAdminOrdersByStatusId?.meta?.per_page,
        //   showSizeChanger: true,
        //   pageSizeOptions: ["10", "20", "30", "40"],
        //   showQuickJumper: true,
        //   total: allAdminOrdersByStatusId?.meta?.total,
        //   style: {
        //     display: "flex",
        //     justifyContent: "center",
        //     marginTop: "20px",
        //   },
        // }}
        pagination={
          isSearched
            ? {
              current: currentPage,
              pageSize: pageSize,
              total: filteredData?.length,
              showSizeChanger: true,
              showQuickJumper: true,
              onShowSizeChange: onShowSizeChange,
              showTotal: (total) => `Total ${total} items`,
            }
            : {
              pageSize: allAdminOrdersByStatusId?.meta?.per_page,
              showSizeChanger: false,
              pageSizeOptions: ["10", "20", "30", "40"],
              showQuickJumper: true,
              total: allAdminOrdersByStatusId?.meta?.total,
              style: {
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              },
            }
          }
      />
      <ViewOrderDetailsModal
        orderRejectedError={orderRejectedError}
        orderStatus={orderStatus}
        orderRejected={orderRejected}
        initialStage={initialStage}
        processingStage={processingStage}
        loadingStage={loadingStage}
        pickUpStage={pickUpStage}
        isArchived={isArchived}
        id={pickupOrderId}
        open={open}
        setOpen={setOpen}
        onCreate={onCreate}
        onCancel={() => setOpen(false)}
        initialValues={{
          modifier: "public",
        }}
        handleAllAdminOrderByStatusIdApi={handleAllAdminOrderByStatusIdApi}
      />{" "}
    </div>
  );
};

export default AvailableOrders;
