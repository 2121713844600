import React, { useCallback, useEffect, useState } from "react";
import { Table, Spin, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserAccount,
  getAllIndependentDistributorship,
  postCreateExclusiveCustomer,
  resetCustomerDetails,
  restoreExclusiveCreation,
  searchCustomers,
  updateBusinessData,
  updateUserData,
} from "../../../redux/Actions";
import moment from "moment";
import { getAuthReducer } from "../../../redux/Reducer/authReducer";
import AddCustomerModal from "../../../Components/AddCustomersModal/addCustomersModal";
import ViewCustomerModal from "../../../Components/AddCustomersModal/viewCustomerModal";
import { DeleteIcon, SearchIcon, ViewIcon } from "../../../asset/icons/icons";
import { tag } from "./distributorCustomers";
import AvatarImg from "../../../Components/AvatarWithImage/AvatarImg";
import { useMatchMedia } from "../../../Utils/media";
import { EditOutlined } from "@ant-design/icons";
import DeleteModal from "../../../Components/DeleteCustomersModal/DeleteModal";
import { debounce } from "lodash";



const usermanText = {
  width: "189px",
  flexShrink: 0,
  color: "#45464E",
  fontFamily: '"MADE TOMMY"',
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "28px",
};

// const exportButton = {
//   display: "flex",
//   height: "44px",
//   padding: "10px 16px",
//   justifyContent: "center",
//   alignItems: "center",
//   gap: "8px",
//   borderRadius: "8px",
//   border: "1px solid var(--Gray-100, #E6E7EC)",
//   background: "var(--Backgrounds-Background-White, #FFF)",
//   fontFamily: '"MADE TOMMY"',
//   fontSize: "14px",
//   fontStyle: "normal",
//   fontWeight: 400,
//   lineHeight: "20px",
//   color: "var(--Payaza-Colors-Payaza-Blue-800, #0E2354)",
//   marginRight: "5px",
// };

const newEmpButton = {
  display: "flex",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  background: "#252A4D",
  color: "#FFF",
  fontFamily: '"MADE TOMMY"',
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
};

// const filter = {
//   color: "var(--Black-80, #52575C)",
//   fontFamily: "Inter",
//   fontSize: "13.138px",
//   fontStyle: "normal",
//   fontWeight: 500,
//   lineHeight: "22.992px",
//   letterSpacing: "-0.145px",
// };

// const filterDiv = {
//   display: "flex",
//   width: "588.756px",
//   justifyContent: "flex-end",
//   alignItems: "flex-start",
//   gap: "16.423px",
//   flexShrink: 0
// };
// const filterCount = {
//   borderRadius: "410.569px",
//   background: "var(--Nibble-baby-blue, rgba(37, 42, 77, 0.08))",
//   display: "flex",
//   padding: "3.285px 6.138px 4.423px 6.57px",
//   justifyContent: "center",
//   alignItems: "center",
//   color: "var(--Black-80, #52575C)",
//   textAlign: "center",
//   fontFamily: "Inter",
//   fontSize: "9.854px",
//   fontStyle: "normal",
//   fontWeight: 400,
//   lineHeight: "normal"
// };


const ExclusiveCustomers = () => {
  const {
    allApplicant,
    isloadingAllApplicant,
    createExclusive,
    postingCreateExclusive,
    authUser,
    userDataUpdated,
    businessDataUpdated,
    userAccountDeleted,
    isdeletingUserAccount,
    isSearched
  } = useSelector(getAuthReducer);
  const dispatch = useDispatch();
  const [isAddCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [type, setType] = useState("");
  const [retailerDetails, setRetailerDetails] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isViewCustomerModalOpen, setViewCustomerModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const userRole = authUser?.user?.roles || {}
  const isSuperAdmin = (userRole?.id === 29 || userRole?.name === "Super Admin")
  const user_type_id = 5;

  const [addExclusiveCustomer, setAddExclusiveCustomer] = useState({
    name: "",
    avatar: "The best bakery in-town",
    country: "Nigeria",
    state: "",
    lga: "",
    phone: "",
    location_id: 0,
    business: {
      name: "",
      description: "The best bakery in-town",
    },
  });
  // const [updateExclusiveCustomer, setUpdateExclusiveCustomer] = useState({
  //   name: "",
  //   avatar: "The best bakery in-town",
  //   country: "Nigeria",
  //   state: "",
  //   lga: "",
  //   phone: "",
  //   location_id: 1,
  //   business: {
  //     name: "",
  //     description: "The best bakery in-town",
  //   },
  // });


  const [image, setImage] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const isMobileDevice = useMatchMedia('(max-width:450px)', true)

  const handleUpdateValues = async (values) => {
    const businessPayload = {
      id: retailerDetails?.business?.id,
      body: {
        name: values.company_name,
        address: values.businessAddress,
        country: values.country,
        state: values.state,
        lga: values.localGovernment,
      }
    }
    const userPayload = {
      id: retailerDetails?.id,
      body: {
        user_type_id: 5,
        name: values.managers_name,
        phone: values.businessPhoneNumber,
        avatar: image,
        email: values.email,
        location_id: values.location_id
      }
    }
    await dispatch(updateBusinessData(businessPayload))
    await dispatch(updateUserData(userPayload))
    // console.log(businessPayload, "Business Payload");
    // console.log(userPayload, "User Payload");
  }

  const showModal = () => {
    setAddCustomerModalOpen(true);
  };
  const handleOk = (values) => {
    console.log(values, "The values");
    let payload = {
      name: values?.name,
      country: "Nigeria",
      state: values?.state,
      lga: values?.localGovernment,
      phone: values?.phone,
      location_id: values?.location_id,
      email: values?.email,
      business: {
        name: values?.businessName,
        address: values?.businessAddress,
        description: "The best bakery in-town",
      },
    }
    dispatch(
      postCreateExclusiveCustomer({
        ...payload,
        avatar: image,
        address: values?.residentAddress,
      })
    );

    // let payload = {
    //   ...values,
    //   country:"Nigeria"
    // }
    // dispatch(
    //   postCreateExclusiveCustomer({
    //     ...payload,
    //     avatar: image,
    //     address: values?.residentAddress,
    //   })
    // );


  };
  const handleCancel = () => {
    setAddCustomerModalOpen(false);
    setAddExclusiveCustomer({
      name: "",
      avatar: "The best bakery in-town",
      country: "Nigeria",
      state: "",
      lga: "",
      phone: "",
      location_id: 0,
      business: {
        name: "",
        description: "The best bakery in-town",
      },
    })
  };

  const showViewCustomerModal = (type, value) => {
    setType(type)
    setRetailerDetails(value)
    setViewCustomerModalOpen(true);
  };
  const handleOpenDeleteModal = (value) => {
    setOpenDeleteModal(true)
    setRetailerDetails(value)
  }
  const handleCancelDeleteModal = () => {
    setOpenDeleteModal(false)
  }
  const handleDeleteAccount = () => {
    dispatch(deleteUserAccount(retailerDetails?.id))
  }
  const handleViewCustomerCancel = () => {
    setViewCustomerModalOpen(false);
  };


  useEffect(() => {
    dispatch(getAllIndependentDistributorship({ user_type_id, page: 1 }));
  }, [dispatch, user_type_id]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value.length > 0) {
        let payload = {
          searchQuery: value,
          user_type_id: user_type_id
        }
        dispatch(resetCustomerDetails())
        dispatch(searchCustomers(payload));
      } else {
        dispatch(getAllIndependentDistributorship({ user_type_id, page: 1 }));
      }
    }, 300),
    [dispatch, user_type_id]
  );
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setCurrentPage(1);
    debouncedSearch(value);
  };


  useEffect(() => {
    if (createExclusive === true) {
      setAddCustomerModalOpen(false);
      dispatch(getAllIndependentDistributorship({ user_type_id }));
    } else {
    } return () => {
      dispatch(restoreExclusiveCreation())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createExclusive]);

  console.log(createExclusive, "createDistributor");

  useEffect(() => {
    if (businessDataUpdated || userDataUpdated || userAccountDeleted) {
      setViewCustomerModalOpen(false);
      setOpenDeleteModal(false)
      // dispatch(getAllIndependentDistributorship({ user_type_id }));
      const payload = {
        user_type_id: 5,
        page: currentPage,
      };
      // dispatch(getAllIndependentDistributorship({ user_type_id }));
      dispatch(getAllIndependentDistributorship(payload));
    }
  }, [businessDataUpdated, userDataUpdated, userAccountDeleted, currentPage, dispatch])
  useEffect(() => {
    if (allApplicant?.data?.length > 0) {
      console.log(allApplicant?.data, "data comes to component")
      setFilteredData(allApplicant?.data);
      setCurrentPage(1);
      setPageSize(10);
    } else {
      setFilteredData([]);
    }
  }, [allApplicant?.data]);


  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);

    if (!isSearched) {
      // Only fetch from API if not searching
      const payload = {
        user_type_id: user_type_id,
        page: pagination.current
      }
      dispatch(getAllIndependentDistributorship(payload));
    }
  };
  const onShowSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };


  const RenderEditButton = ({ record }) => {
    return (
      <>
        {userRole?.id === 29 || userRole?.name === "Super Admin" ?
          <Button
            type="text"
            className="d-inline-flex p-2"
            onClick={() => showViewCustomerModal('edit', record)}
          >
            <EditOutlined style={{ padding: "0px 5px", height: "fit-content", fontSize: "17px" }} />
          </Button>
          : null}
      </>
    )
  }

  const columns = [
    {
      title: "Name",
      dataIndex: ["business", "name"],
      // render: (text) => <span className="orderHistory-text">{text}</span>,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AvatarImg src={record?.avatar} name={record?.business?.name} />
          <span className="orderHistory-text" style={{ marginLeft: 8 }}>{record?.business?.name ?? 'NIL'}</span>
        </div>
      ),
      sorter: (a, b) => a.business?.name?.localeCompare(b?.business?.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: (a, b) => a.email?.localeCompare(b?.email),
    },
    {
      title: "Location",
      dataIndex: ["business", "state"],
      render: (text) => <span className="orderHistory-text">{text ?? "-"}</span>,
      sorter: (a, b) => a?.business?.state?.localeCompare(b?.business?.state),
    },
    {
      title: "Total Amount Spent",
      dataIndex: "phone",
      render: (text) => <div className="d-flex justify-content-end"><span className="orderHistory-text">{"₦0"}</span></div>,
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 1,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      render: (text) => <span className="orderHistory-text">{text}</span>,
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 1,
      },
    },
    {
      title: "Date Joined",
      dataIndex: ["business", "created_at"],
      render: (text) => (
        <span className="orderHistory-text">
          {text !== null ? moment(text).format("MMM Do YYYY") : "NIL"}
        </span>
      ),
      sorter: (a, b) => a.created_at?.localeCompare(b?.created_at),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <>
          <Button onClick={() => showViewCustomerModal("view", record)} type="text" className="orderHistory-view-details">
            <ViewIcon />
          </Button>
          <RenderEditButton record={record} />
          {isSuperAdmin ?
            <Button onClick={() => handleOpenDeleteModal(record)} size="large" icon={<DeleteIcon />} type="text" className="orderHistory-view-details">
            </Button> : null}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="p-3 mt-5" style={{ borderRadius: "0px" }}>
        <div className="row mb-4">
          <div className={isMobileDevice ? "d-flex flex-column" : "d-flex justify-content-start"}>
            <div className="col">
              <span style={usermanText}>
                Customers {">"} Direct Customers
                <span className="mx-2" style={tag}>
                  {isSearched ? filteredData?.length : filteredData?.length === 0 ? 0 : allApplicant?.meta?.total}
                </span>
              </span>
            </div>
            <div className="col d-flex justify-content-end">
              {/* <button onClick={1} style={exportButton}>
                Export
              </button> */}
              <button style={newEmpButton} onClick={showModal}>
                Add Direct Customer
              </button>
              <AddCustomerModal
                isModalOpen={isAddCustomerModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                usertypeid={user_type_id}
                setAddCustomerField={setAddExclusiveCustomer}
                addCustomerField={addExclusiveCustomer}
                setImage={setImage}
                image={image}
                postingCustomer={postingCreateExclusive}
              />
            </div>
          </div>
        </div>

        {/* <div className="row mt-5 mb-4">
            <div className="d-flex justify-content-start">
              <div className="col-3">
                <Input placeholder="Basic usage" />
              </div>
              <div className="col d-flex justify-content-end" style={filterDiv}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M11.9507 10.0641V16.5346C11.9836 16.781 11.9015 17.0437 11.7126 17.2162C11.3924 17.5364 10.8751 17.5364 10.5548 17.2162L8.90433 15.5657C8.71546 15.3768 8.63335 15.1223 8.6662 14.8842V10.0641H8.64156L3.91181 4.00407C3.63262 3.65098 3.69831 3.13367 4.0514 2.85448C4.20742 2.73952 4.37986 2.67383 4.56051 2.67383H16.0564C16.2371 2.67383 16.4095 2.73952 16.5655 2.85448C16.9186 3.13367 16.9843 3.65098 16.7051 4.00407L11.9754 10.0641H11.9507Z"
                      fill="#52575C"
                    />
                  </svg>
                </span>{" "}
                <span style={filter} className="">
                  Filter
                </span>
                <span style={filterCount}>0</span>
              </div>
            </div>
          </div> */}
        <div className="d-flex justify-content-start mb-3">
          <div className="col-md-5 col-sm-5 col-12 mt-3">
            <Input
              placeholder={"Search direct customers"}
              style={{ width: '100%', border: 'none', color: '#BECOCA', backgroundColor: '#F7F7F7', cursor: "text" }}
              value={searchQuery}
              onChange={handleSearch}
              prefix={<SearchIcon />}
            />
          </div>
        </div>
        <Spin spinning={isloadingAllApplicant} tip="Loading...">
          <Table
            columns={columns}
            // dataSource={allApplicant?.data}
            scroll={{ x: 1000 }}
            dataSource={filteredData}
            onChange={onChange}
            pagination={
              isSearched
                ? {
                  current: currentPage,
                  pageSize: pageSize,
                  total: filteredData?.length,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  onShowSizeChange: onShowSizeChange,
                  showTotal: (total) => `Total ${total} items`,
                }
                : {
                  current: allApplicant?.meta?.current_page,
                  pageSize: allApplicant?.meta?.per_page,
                  total: allApplicant?.meta?.total,
                  showSizeChanger: false,
                  pageSizeOptions: ["10", "20", "30", "40"],
                  showQuickJumper: true,
                  onShowSizeChange: onShowSizeChange,
                  showTotal: (total) => `Total ${total} items`,
                }
            }
          />

        </Spin>
      </div>
      <ViewCustomerModal
        isViewCustomerModalOpen={isViewCustomerModalOpen}
        usertypeid={user_type_id}
        handleViewCustomerCancel={handleViewCustomerCancel}
        details={retailerDetails}
        image={image}
        setImage={setImage}
        type={type}
        handleUpdateValues={handleUpdateValues}

      />
      <DeleteModal open={openDeleteModal} handleDelete={handleDeleteAccount} onCancel={handleCancelDeleteModal} name={retailerDetails?.business?.name} loading={isdeletingUserAccount} type={"Direct"} />
    </>
  );
};
export default ExclusiveCustomers;
